import React from 'react';
import { Youtube, Construction } from 'lucide-react';

export default function YouTubeAuthPage() {
  return (
    <div className="max-w-2xl mx-auto py-4 sm:py-8 px-4">
      <div className="bg-white rounded-lg shadow-sm p-4 sm:p-6">
        <div className="flex items-center gap-3 mb-6">
          <Youtube className="w-6 h-6 text-red-600" />
          <h1 className="text-xl sm:text-2xl font-bold">YouTube Settings</h1>
        </div>
        
        {/* Under Construction Banner */}
        <div className="mb-6 bg-yellow-50 border-l-4 border-yellow-400 p-4">
          <div className="flex items-center">
            <Construction className="h-5 w-5 text-yellow-400 mr-2" />
            <p className="text-sm text-yellow-700">
              <span className="font-bold">Under Construction:</span> YouTube authentication is currently disabled while we work on a better solution for handling highlights. Check back later for updates!
            </p>
          </div>
        </div>

        {/* Future Features Section */}
        <div className="space-y-4 text-gray-600">
          <h2 className="text-lg font-semibold text-gray-900">
            Planned Features
          </h2>
          <p className="text-sm sm:text-base">
            When YouTube integration is ready, you'll be able to:
          </p>
          <div className="space-y-3 py-2">
            <div className="flex items-start gap-3 bg-gray-50 p-3 rounded-lg">
              <div className="flex-shrink-0 w-1.5 h-1.5 mt-2 rounded-full bg-red-500" />
              <span className="text-sm sm:text-base">Access your YouTube watch history</span>
            </div>
            <div className="flex items-start gap-3 bg-gray-50 p-3 rounded-lg">
              <div className="flex-shrink-0 w-1.5 h-1.5 mt-2 rounded-full bg-red-500" />
              <span className="text-sm sm:text-base">Create automatic playlists for saved games</span>
            </div>
            <div className="flex items-start gap-3 bg-gray-50 p-3 rounded-lg">
              <div className="flex-shrink-0 w-1.5 h-1.5 mt-2 rounded-full bg-red-500" />
              <span className="text-sm sm:text-base">Get personalized highlight recommendations</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
} 