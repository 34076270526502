import React from 'react';
import { Bookmark } from 'lucide-react';
import { useApp } from '../contexts/AppContext';

export default function QueueButton({ gameId }) {
  const { queue, addToQueue, removeFromQueue } = useApp();
  const isInQueue = queue.includes(gameId);

  const handleClick = (e) => {
    e.preventDefault();
    if (isInQueue) {
      removeFromQueue(gameId);
    } else {
      addToQueue(gameId);
    }
  };

  return (
    <button
      onClick={handleClick}
      className="text-gray-400 hover:text-blue-500 transition-colors"
      title={isInQueue ? "Remove from Queue" : "Add to Queue"}
      data-bookmark-button={gameId}
    >
      <Bookmark 
        className={`w-4 h-4 ${isInQueue ? 'fill-current' : ''}`}
        data-bookmark-dummy-1={gameId === 'dummy-1' ? true : undefined}
      />
    </button>
  );
} 