import { initializeApp } from 'firebase/app';
import { getAuth, setPersistence, browserLocalPersistence } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: 'AIzaSyCijAHIToOw1bMj8AWVR1msn1qyzAyt_XM',
  authDomain: 'spoilsports.firebaseapp.com',
  projectId: 'spoilsports',
  storageBucket: 'spoilsports.firebasestorage.app',
  messagingSenderId: '494710120075',
  appId: '1:494710120075:web:5f008353c0152065d4fe8e',
  measurementId: 'G-LG7PLQJM9H'
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize services
export const auth = getAuth(app);
export const db = getFirestore(app);

// Properly set persistence
setPersistence(auth, browserLocalPersistence);

export default app; 