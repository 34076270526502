import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  RouterProvider,
  createRoutesFromElements,
  Route,
} from 'react-router-dom';
import { AppProvider } from './contexts/AppContext';
import App from './App';
import { Login } from './components/Login';
import { Register } from './components/Register';
import { ProtectedRoute } from './components/ProtectedRoute';
import GamesPage from './GamesPage';
import FollowingPage from './FollowingPage';
import QueuePage from './pages/QueuePage';
import ErrorBoundary from './components/ErrorBoundary';
import DevNotesPage from './pages/DevNotesPage';
import YouTubeAuthPage from './pages/YouTubeAuthPage';
import AboutPage from './pages/AboutPage';
import TeamPage from './pages/TeamPage';
import ChannelsPage from './pages/ChannelsPage';
import PreferencesPage from './pages/PreferencesPage';
import { ForgotPassword } from './components/ForgotPassword';
import './index.css';

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<AppProvider />} errorElement={<ErrorBoundary />}>
      <Route element={<App />}>
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route
          path="/"
          element={
            <ProtectedRoute>
              <GamesPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/follow"
          element={
            <ProtectedRoute>
              <FollowingPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/queue"
          element={
            <ProtectedRoute>
              <QueuePage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/dev-notes"
          element={
            <ProtectedRoute>
              <DevNotesPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/youtube-auth"
          element={
            <ProtectedRoute>
              <YouTubeAuthPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/about"
          element={
            <ProtectedRoute>
              <AboutPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/team/:teamId"
          element={
            <ProtectedRoute>
              <TeamPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/channels"
          element={
            <ProtectedRoute>
              <ChannelsPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/preferences"
          element={
            <ProtectedRoute>
              <PreferencesPage />
            </ProtectedRoute>
          }
        />
      </Route>
    </Route>
  ),
  {
    future: {
      v7_startTransition: true,
      v7_relativeSplatPath: true,
      v7_fetcherPersist: true,
      v7_normalizeFormMethod: true,
      v7_partialHydration: true,
      v7_skipActionErrorRevalidation: true
    }
  }
);

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
); 