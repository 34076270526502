import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useApp } from '../contexts/AppContext';
import GamesDisplay from '../components/GamesDisplay';
import { sportsApi } from '../services/sportsApi';

function TeamPage() {
  const { teamId } = useParams();
  const [team, setTeam] = useState(null);
  const [games, setGames] = useState([]);
  const [loading, setLoading] = useState(true);
  const { following, toggleFollowTeam } = useApp();

  useEffect(() => {
    const loadTeamData = async () => {
      try {
        setLoading(true);
        // Extract sport from teamId (e.g., "hockey-13" -> "hockey")
        const sport = teamId.split('-')[0];
        
        // Load team games
        const teamGames = await sportsApi.getTeamGames(teamId, sport);
        setGames(teamGames);
        
        // Get team details if available
        const teamData = await sportsApi.getTeamDetails(teamId);
        setTeam(teamData);
      } catch (error) {
        console.error('Error loading team data:', error);
      } finally {
        setLoading(false);
      }
    };

    if (teamId) {
      loadTeamData();
    }
  }, [teamId]);

  if (loading) {
    return (
      <div className="p-4 text-center text-gray-500">
        Loading team data...
      </div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      {team && (
        <div className="mb-6">
          <div className="flex items-center gap-4 mb-4">
            {team.logo && (
              <img 
                src={team.logo} 
                alt={team.name} 
                className="w-16 h-16 object-contain"
              />
            )}
            <div>
              <h1 className="text-2xl font-bold text-gray-900">{team.name}</h1>
              {team.league && (
                <p className="text-sm text-gray-500">{team.league}</p>
              )}
            </div>
            <button
              onClick={() => toggleFollowTeam(teamId)}
              className={`ml-auto px-4 py-2 rounded-md text-sm font-medium ${
                following.includes(teamId)
                  ? 'bg-blue-100 text-blue-700 hover:bg-blue-200'
                  : 'bg-blue-600 text-white hover:bg-blue-700'
              }`}
            >
              {following.includes(teamId) ? 'Following' : 'Follow'}
            </button>
          </div>
        </div>
      )}

      <div className="mb-8">
        <h2 className="text-xl font-semibold text-gray-900 mb-4">Team Games</h2>
        <GamesDisplay 
          initialGames={games}
          showFilters={false}
          bypassInternalFilters={true}
        />
      </div>
    </div>
  );
}

export default TeamPage; 