import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { auth, db } from '../services/firebase';
import { onAuthStateChanged } from 'firebase/auth';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { sportsApi } from '../services/sportsApi';
import { LEAGUES } from '../config/leagues';
import LoadingSpinner from '../components/LoadingSpinner';

export const AppContext = createContext();

export function AppProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null);
  const [following, setFollowing] = useState([]);
  const [spoilerMode, setSpoilerMode] = useState(false);
  const [loading, setLoading] = useState(true);
  const [games, setGames] = useState([]);
  const [teams, setTeams] = useState([]);
  const [queue, setQueue] = useState([]);
  const [isGuest, setIsGuest] = useState(false);
  const [isNewGuest, setIsNewGuest] = useState(false);
  const [defaultFilters, setDefaultFilters] = useState(null);
  const [sessionFilters, setSessionFilters] = useState(null);
  const [activeFilters, setActiveFilters] = useState(null);
  const [isFirstTimeUser, setIsFirstTimeUser] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);
  const navigate = useNavigate();

  const fetchTeamData = async (teamId) => {
    const [sport, espnId] = teamId.split('-');
    
    // Find the league for this sport
    const league = Object.values(LEAGUES).find(l => l.sport === sport);
    if (!league) {
      throw new Error(`Unsupported sport: ${sport}`);
    }
    
    const endpoint = `https://site.api.espn.com/apis/site/v2/sports/${sport}/${league.espnCode}/teams/${espnId}`;
    
    const response = await fetch(endpoint);
    const data = await response.json();
    
    return {
      id: teamId,
      espnId: data.team.id,
      name: data.team.displayName,
      abbreviation: data.team.abbreviation,
      logo: data.team.logos?.[0]?.href,
      league: league.name,
      sport: sport.toUpperCase(),
      color: data.team.color || '000000',
      alternateColor: data.team.alternateColor,
      record: `${data.team.record?.items?.[0]?.summary || 'N/A'}`
    };
  };

  const loadTeam = async (teamId) => {
    try {
      const teamData = await fetchTeamData(teamId);
      setTeams(current => {
        if (!current.find(t => t.id === teamId)) {
          return [...current, teamData];
        }
        return current;
      });
    } catch (error) {
      console.error('Error loading team:', error);
    }
  };

  const normalizeTeamIds = (following) => {
    return following.map(async (teamId) => {
      const [sport, id] = teamId.split('-');
      if (sport === 'soccer' && !teamId.includes('.')) {
        // This is an old format ID, try to get the correct league
        try {
          const response = await fetch(`${API_BASE_URL}/soccer/teams/${id}`);
          if (response.ok) {
            const data = await response.json();
            const league = data.sports?.[0]?.leagues?.[0]?.slug;
            if (league) {
              return `soccer-${league}-${id}`;
            }
          }
        } catch (error) {
          console.warn('Error normalizing team ID:', error);
        }
      }
      return teamId;
    });
  };

  // Combine filter initialization into one place
  const initializeFilters = useCallback(async (userData = null) => {
    const defaultFilterValues = {
      date: ['recent'],
      leagues: ['my-teams']
    };

    try {
      let filters;
      
      if (userData?.defaultFilters) {
        // Use user's saved filters
        filters = userData.defaultFilters;
      } else if (isGuest && isNewGuest) {
        // Use default filters for new guests
        filters = defaultFilterValues;
      } else {
        // Use empty filters as fallback
        filters = { date: [], leagues: [] };
      }

      // Ensure filters have required properties
      filters = {
        date: Array.isArray(filters.date) ? filters.date : defaultFilterValues.date,
        leagues: Array.isArray(filters.leagues) ? filters.leagues : defaultFilterValues.leagues
      };

      setDefaultFilters(filters);
      setActiveFilters(filters);
      setSessionFilters(filters);
      
    } catch (error) {
      console.error('Error initializing filters:', error);
      // Set safe defaults
      setDefaultFilters(defaultFilterValues);
      setActiveFilters(defaultFilterValues);
      setSessionFilters(defaultFilterValues);
    }
  }, [isGuest, isNewGuest]);

  // Update auth listener
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      console.log('Auth state changed:', user?.email);
      setCurrentUser(user);
      
      try {
        if (user) {
          setIsGuest(false);
          const userDoc = await getDoc(doc(db, 'users', user.uid));
          if (userDoc.exists()) {
            const userData = userDoc.data();
            setFollowing(userData.following || []);
            setSpoilerMode(userData.spoilerMode ?? true);
            setQueue(userData.queue || []);
            setIsFirstTimeUser(!userData.hasCompletedGuide);
            await initializeFilters(userData);
          } else {
            setIsFirstTimeUser(true);
            await initializeFilters();
          }
          navigate('/');
        } else {
          setIsGuest(true);
          setFollowing([]);
          setSpoilerMode(false);
          setQueue([]);
          await initializeFilters();
          
          if (!isGuest && 
              window.location.pathname !== '/login' && 
              window.location.pathname !== '/register') {
            navigate('/login');
          }
        }
      } catch (error) {
        console.error('Error in auth state change:', error);
        await initializeFilters(); // Use defaults on error
      } finally {
        setIsInitialized(true);
        setLoading(false);
      }
    });

    return unsubscribe;
  }, [navigate, isGuest, initializeFilters]);

  // Load games for followed teams
  useEffect(() => {
    const loadGames = async () => {
      try {
        const daysRange = { past: 7, future: 7 };
        const allGames = await sportsApi.getAllGames(daysRange);
        
        // Filter games based on followed teams OR queued games
        const filteredGames = allGames.filter(game => {
          const isFollowedTeam = following.includes(game.homeTeam.id) || 
                                following.includes(game.awayTeam.id);
          const isQueued = queue.includes(game.id);
          const league = Object.values(LEAGUES).find(l => l.name === game.league);
          return (isFollowedTeam || isQueued) && league;
        });
        setGames(filteredGames);
      } catch (error) {
        console.error('Error loading games:', error);
      }
    };

    loadGames();
  }, [following, queue]);

  const toggleFollow = async (teamId) => {
    if (!currentUser) return;

    const newFollowing = following.includes(teamId)
      ? following.filter(id => id !== teamId)
      : [...following, teamId];

    setFollowing(newFollowing);

    try {
      await updateDoc(doc(db, 'users', currentUser.uid), {
        following: newFollowing
      });
    } catch (error) {
      console.error('Error updating following:', error);
      setFollowing(following); // Revert on error
    }
  };

  const toggleSpoilerMode = useCallback(async () => {
    const newMode = !spoilerMode;
    setSpoilerMode(newMode);
    localStorage.setItem('spoilerMode', JSON.stringify(newMode));

    if (currentUser) {
      try {
        await updateDoc(doc(db, 'users', currentUser.uid), {
          spoilerMode: newMode
        });
      } catch (error) {
        console.error('Error updating spoiler mode:', error);
        // Revert on error
        setSpoilerMode(!newMode);
        localStorage.setItem('spoilerMode', JSON.stringify(!newMode));
      }
    }
  }, [spoilerMode, currentUser]);

  const toggleQueue = async (gameId) => {
    if (!currentUser) return;

    const newQueue = queue.includes(gameId)
      ? queue.filter(id => id !== gameId)
      : [...queue, gameId];

    setQueue(newQueue);

    try {
      await updateDoc(doc(db, 'users', currentUser.uid), {
        queue: newQueue
      });
    } catch (error) {
      console.error('Error updating queue:', error);
      setQueue(queue); // Revert on error
    }
  };

  const addToQueue = useCallback(async (gameId) => {
    if (!currentUser) return;
    
    const newQueue = [...queue, gameId];
    setQueue(newQueue);
    
    // Persist to Firestore
    try {
      await updateDoc(doc(db, 'users', currentUser.uid), {
        queue: newQueue
      });
    } catch (error) {
      console.error('Error updating queue:', error);
      // Optionally revert the local state if the save fails
      setQueue(queue);
    }
  }, [queue, currentUser]);

  const removeFromQueue = useCallback(async (gameId) => {
    if (!currentUser) return;
    
    const newQueue = queue.filter(id => id !== gameId);
    setQueue(newQueue);
    
    // Persist to Firestore
    try {
      await updateDoc(doc(db, 'users', currentUser.uid), {
        queue: newQueue
      });
    } catch (error) {
      console.error('Error updating queue:', error);
      // Optionally revert the local state if the save fails
      setQueue(queue);
    }
  }, [queue, currentUser]);

  // Make sure to load the queue when the user logs in
  useEffect(() => {
    if (currentUser?.queue) {
      setQueue(currentUser.queue);
    } else {
      setQueue([]); // Reset queue when user logs out
    }
  }, [currentUser]);

  const followTeam = (teamId) => {
    setCurrentUser(currentUser => {
      if (!currentUser) return currentUser;
      
      const following = currentUser.following || [];
      if (!following.includes(teamId)) {
        const updatedFollowing = [...following, teamId];
        return {
          ...currentUser,
          following: updatedFollowing
        };
      }
      return currentUser;
    });
  };

  const unfollowTeam = (teamId) => {
    setCurrentUser(currentUser => {
      if (!currentUser) return currentUser;
      
      const following = currentUser.following || [];
      const updatedFollowing = following.filter(id => id !== teamId);
      return {
        ...currentUser,
        following: updatedFollowing
      };
    });
  };

  const setGuestMode = useCallback((value) => {
    console.log('Setting guest mode to:', value);
    setIsGuest(value);
    if (value) {
      // Initialize guest data
      setFollowing([]);
      setSpoilerMode(false);
      setQueue([]);
      // Set initial filters for first-time guests only
      if (isNewGuest) {
        setDefaultFilters({
          date: ['recent'],
          leagues: ['my-teams']
        });
      }
      navigate('/', { replace: true });
    }
  }, [navigate, isNewGuest]);

  // Add new function to update filters
  const updateDefaultFilters = useCallback(async (newFilters) => {
    // If newFilters is a function (from setState callback), resolve it first
    const resolvedFilters = typeof newFilters === 'function' 
      ? newFilters(defaultFilters)
      : newFilters;

    // Create a new plain object with only the necessary data
    const plainFilters = {
      date: Array.isArray(resolvedFilters.date) ? resolvedFilters.date.slice() : [],
      leagues: Array.isArray(resolvedFilters.leagues) ? resolvedFilters.leagues.slice() : []
    };

    setDefaultFilters(plainFilters);
    
    if (currentUser) {
      try {
        await updateDoc(doc(db, 'users', currentUser.uid), {
          defaultFilters: {
            date: plainFilters.date,
            leagues: plainFilters.leagues
          }
        });
      } catch (error) {
        console.error('Error updating default filters:', error);
        console.log('Attempted to save filters:', plainFilters);
      }
    }
  }, [currentUser, defaultFilters]);

  useEffect(() => {
    const loadFollowing = async () => {
      const savedFollowing = localStorage.getItem('following');
      if (savedFollowing) {
        const following = JSON.parse(savedFollowing);
        const normalizedFollowing = await Promise.all(following.map(normalizeTeamIds));
        setFollowing(normalizedFollowing);
      }
    };
    loadFollowing();
  }, []);

  // Add function to mark guide as completed
  const markGuideComplete = async () => {
    if (currentUser) {
      try {
        await updateDoc(doc(db, 'users', currentUser.uid), {
          hasCompletedGuide: true
        });
        setIsFirstTimeUser(false);
      } catch (error) {
        console.error('Error updating guide completion:', error);
      }
    }
  };

  // Initialize active filters from defaults when component mounts
  useEffect(() => {
    if (!activeFilters) {
      setActiveFilters(defaultFilters);
    }
  }, [defaultFilters]);

  // Load user preferences when authenticated
  useEffect(() => {
    if (isAuthenticated) {
      // Load user's default filters from your backend/storage
      loadUserPreferences().then(prefs => {
        setDefaultFilters(prefs.filters);
      });
    }
  }, [isAuthenticated]);

  // Clear session filters on logout if you have that functionality
  const handleLogout = () => {
    setSessionFilters(null);
    // ... other logout logic
  };

  const value = {
    currentUser,
    following,
    spoilerMode,
    loading,
    games,
    teams,
    queue,
    toggleFollow,
    toggleSpoilerMode,
    toggleQueue,
    addToQueue,
    removeFromQueue,
    loadTeam,
    followTeam,
    unfollowTeam,
    setSpoilerMode,
    isGuest,
    setGuestMode,
    isNewGuest,
    setIsNewGuest,
    defaultFilters,
    setDefaultFilters,  // Used by PreferencesPage
    activeFilters,
    setActiveFilters,  // Used by GamesDisplay
    isFirstTimeUser,
    markGuideComplete,
    isAuthenticated,
    sessionFilters,
    setSessionFilters,
    isInitialized,
    isLoading: loading || !isInitialized
  };

  return (
    <AppContext.Provider value={value}>
      {loading || !isInitialized ? (
        <div className="min-h-screen bg-gray-50 flex items-center justify-center">
          <LoadingSpinner />
        </div>
      ) : (
        <Outlet />
      )}
    </AppContext.Provider>
  );
}

export const useApp = () => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error('useApp must be used within an AppProvider');
  }
  return context;
}; 