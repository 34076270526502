import React from 'react';
import { useApp } from './contexts/AppContext';
import GamesDisplay from './components/GamesDisplay';

function GamesPage() {
  const { following, spoilerMode, toggleSpoilerMode } = useApp();

  return (
    <div className="container mx-auto px-4 py-4">
      <GamesDisplay 
        following={following} 
        spoilerMode={spoilerMode}
        setSpoilerMode={toggleSpoilerMode}
        showFilters={true}
      />
    </div>
  );
}

export default GamesPage;