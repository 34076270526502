import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import GamesDisplay from './GamesDisplay';
import { Play, ChevronRight, ChevronLeft } from 'lucide-react';
import QueueButton from './QueueButton';

export default function GuestGuide({ onComplete }) {
  console.log('GuestGuide render');
  
  const [currentStep, setCurrentStep] = useState(0);
  const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });
  const navigate = useNavigate();

  const steps = [
    {
      target: '[data-nav-following]',
      content: 'Head to the follow page where you can add your favorite teams.',
      position: 'bottom',
      emoji: '⭐'
    },
    {
      target: '[data-filter-button]',
      content: 'Click the + icon to see more games and leagues.',
      position: 'bottom',
      emoji: '➕'
    },
    {
      target: '[data-highlights-button="dummy-2"]',
      content: 'A highlights button will appear after the game ends.',
      position: 'bottom',
      emoji: '🎥'
    },
    {
      target: '[data-bookmark-button="dummy-1"]',
      content: 'You can save games for later by clicking the bookmark icon.',
      position: 'bottom',
      emoji: '🔖'
    },
    {
      target: '[data-nav-queue]',
      content: 'And all your saved games will be here on your queue page.',
      position: 'bottom',
      emoji: '📋'
    },
    {
      target: '[data-spoiler-mode-section]',
      content: 'Lastly, you can toggle spoiler mode in the preferences page to show / hide results.',
      position: 'bottom',
      emoji: '👀',
      navigateBeforeShow: true
    }
  ];

  // Update the tooltip position calculation
  const updateTooltipPosition = useCallback(() => {
    if (currentStep === 0) return;
    
    const step = steps[currentStep - 1];
    const targetElement = document.querySelector(step.target);
    
    if (targetElement) {
      targetElement.classList.add('relative', 'z-[43]');
      
      const gameContainer = targetElement.closest('.game-card');
      if (gameContainer) {
        gameContainer.classList.add('relative', 'z-[43]');
      }
      
      const rect = targetElement.getBoundingClientRect();
      const viewportWidth = window.innerWidth;
      const viewportHeight = window.innerHeight;
      
      const spotlightPosition = {
        top: rect.top + (rect.height / 2),
        left: rect.left + (rect.width / 2)
      };
      
      // Adjust tooltip width based on viewport
      const tooltipWidth = Math.min(300, viewportWidth - 32); // 32px for padding
      const offset = 20;
      
      let top, left;
      
      // Center horizontally on mobile
      if (viewportWidth < 640) { // mobile breakpoint
        left = viewportWidth / 2;
      } else {
        left = Math.min(
          Math.max(tooltipWidth / 2 + 16, rect.left + (rect.width / 2)),
          viewportWidth - (tooltipWidth / 2 + 16)
        );
      }

      // Adjust vertical positioning
      if (step.position === 'bottom') {
        const bottomSpace = viewportHeight - (rect.bottom + offset + 180);
        if (bottomSpace < 0) {
          top = Math.max(16, rect.top - offset - 180);
        } else {
          top = rect.bottom + offset;
        }
      } else {
        top = rect.bottom + offset;
      }

      // Ensure tooltip stays within viewport
      top = Math.max(16, Math.min(top, viewportHeight - 196));

      setTooltipPosition({ 
        top, 
        left,
        spotlightTop: spotlightPosition.top,
        spotlightLeft: spotlightPosition.left
      });
    }
  }, [currentStep, steps]);

  // Update position on step change and window resize
  useEffect(() => {
    updateTooltipPosition();
    window.addEventListener('resize', updateTooltipPosition);
    return () => window.removeEventListener('resize', updateTooltipPosition);
  }, [currentStep, updateTooltipPosition]);

  // Clean up all navigation highlights
  const cleanupAllHighlights = () => {
    // Update selector to include all possible spotlight targets
    const allElements = document.querySelectorAll(`
      [data-nav-home], 
      [data-nav-following], 
      [data-nav-queue], 
      [data-filter-button],
      [data-highlights-button],
      [data-bookmark-button]
    `);
    
    allElements.forEach(element => {
      element.classList.remove('relative', 'z-[43]');
      
      // Clean up parent elements
      const parentElement = element.closest('.game-card') || element.parentElement;
      if (parentElement && !parentElement.classList.contains('nav-container')) {
        parentElement.classList.remove('relative', 'z-[43]');
      }
    });
  };

  // Clean up on mount and unmount
  useEffect(() => {
    cleanupAllHighlights();
    return () => cleanupAllHighlights();
  }, []);

  // Update the highlighting useEffect
  useEffect(() => {
    if (currentStep === 0) return; // Add early return for welcome screen
    
    cleanupAllHighlights(); // Clean up previous highlights
    const step = steps[currentStep - 1];
    const targetElement = document.querySelector(step.target);
    
    if (targetElement) {
      // Only add z-index positioning for all elements
      targetElement.classList.add('relative', 'z-[45]');
      
      // For bookmark icon, also handle its parent container
      if (step.target === '.lucide-bookmark') {
        const parentElement = targetElement.closest('button') || targetElement.parentElement;
        if (parentElement) {
          parentElement.classList.add('relative', 'z-[45]');
        }
      }
      
      return () => {
        targetElement.classList.remove('relative', 'z-[45]');
        
        if (step.target === '.lucide-bookmark') {
          const parentElement = targetElement.closest('button') || targetElement.parentElement;
          if (parentElement) {
            parentElement.classList.remove('relative', 'z-[45]');
          }
        }
      };
    }
  }, [currentStep]);

  // Add scroll management when guide starts
  useEffect(() => {
    if (currentStep === 1) {
      // Reset scroll position when guide starts
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [currentStep]);

  // Update the handleNext function to ensure scroll reset
  const handleNext = () => {
    if (currentStep === 0) {
      // Reset scroll position before starting the guide
      window.scrollTo({ top: 0, behavior: 'smooth' });
      // Small delay to ensure scroll completes before showing first step
      setTimeout(() => {
        setCurrentStep(1);
      }, 100);
    } else if (currentStep < steps.length) {
      const nextStepData = steps[currentStep];
      
      if (nextStepData.navigateBeforeShow) {
        // First cleanup highlights
        cleanupAllHighlights();
        
        // Then navigate to preferences and update step
        navigate('/preferences');
        setCurrentStep(currentStep + 1);
      } else {
        setCurrentStep(currentStep + 1);
      }
    } else {
      cleanupAllHighlights();
      onComplete();
      // Navigate back to home page
      navigate('/');
    }
  };

  const currentTip = currentStep > 0 ? steps[currentStep - 1] : null;

  const isGameElement = (target) => {
    return target.startsWith('[data-game-');
  };

  const shouldHaveWhiteBackground = (target) => {
    return !isGameElement(target);
  };

  const getSpotlightStyles = (target) => {
    return {
      backgroundColor: 'transparent',
      border: '2px solid rgba(255, 255, 255, 1)',
      boxShadow: `
        0 0 15px rgba(255, 255, 255, 0.9),
        0 0 25px rgba(255, 255, 255, 0.4),
        0 0 35px rgba(255, 255, 255, 0.2)
      `
    };
  };

  // Add components for spotlight moments
  const SpotlightHighlightsButton = () => (
    <div 
      className="fixed z-[43]"
      style={{
        top: tooltipPosition.spotlightTop - 4,
        left: tooltipPosition.spotlightLeft,
        transform: 'translate(-50%, -50%)'
      }}
    >
      <button
        data-highlights-button="dummy-2"
        className="mt-2 px-2 py-1 rounded-md text-blue-600 bg-blue-50 hover:bg-blue-100 mx-auto flex items-center gap-1 text-xs"
      >
        <Play size={14} />
        <span>Highlights</span>
      </button>
    </div>
  );

  const SpotlightBookmarkButton = () => (
    <div 
      className="fixed z-[43]"
      style={{
        top: tooltipPosition.spotlightTop,
        left: tooltipPosition.spotlightLeft,
        transform: 'translate(-50%, -50%)'
      }}
    >
      <QueueButton 
        gameId="dummy-1"
        data-bookmark-button="dummy-1"
      />
    </div>
  );

  return (
    <>
      {/* Dim overlay - show from welcome screen onwards */}
      {currentStep >= 0 && (
        <div className="fixed inset-0 bg-black bg-opacity-70 z-[41] transition-opacity duration-300" />
      )}

      {/* Welcome message overlay - show before any steps */}
      {!currentStep && (
        <div className="fixed inset-0 z-[80] flex items-center justify-center">
          <div className="bg-white rounded-xl shadow-xl p-8 max-w-md mx-4 text-center">
            <h2 className="text-2xl font-bold mb-4">Welcome 👋</h2>
            <p className="text-gray-600 mb-6">Let's take a quick tour of the main features</p>
            <div className="flex gap-4 justify-center">
              <button
                onClick={() => setCurrentStep(1)}
                className="bg-blue-600 text-white rounded-lg py-2 px-6 hover:bg-blue-700 transition-colors"
              >
                Start Tour
              </button>
              <button
                onClick={onComplete}
                className="text-gray-600 hover:text-gray-800 transition-colors"
              >
                Skip for now
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Only show tour content when currentStep > 0 */}
      {currentStep > 0 && (
        <>
          {/* Only show dummy games if we haven't navigated to preferences yet */}
          {!steps[currentStep - 1]?.navigateBeforeShow && (
            <div className="fixed z-[40] container mx-auto px-auto sm:px-auto py-auto" style={{ 
              left: '50%',
              transform: 'translateX(-50%)',
              width: '85%',
              maxWidth: '1280px',
              marginTop: '-140px'
            }}>
              <GamesDisplay 
                initialGames={null}
                showFilters={false}
                bypassInternalFilters={true}
                following={[]}
                hideContent={false}
              />
            </div>
          )}

          {/* White background spotlight - move below highlighted elements */}
          <div 
            className="fixed z-[42] transition-all duration-300"
            style={{
              position: 'absolute',
              top: tooltipPosition.spotlightTop,
              left: tooltipPosition.spotlightLeft,
              width: currentStep === 3 ? '120px' : '32px',
              height: currentStep === 3 ? '40px' : '32px',
              borderRadius: '6px',
              pointerEvents: 'none',
              transform: 'translate(-50%, -50%)',
              backgroundColor: 'white',
              opacity: 0.95
            }}
          />

          {/* Main spotlight effect */}
          <div 
            className="fixed z-[44] transition-all duration-300"
            style={{
              position: 'absolute',
              top: tooltipPosition.spotlightTop,
              left: tooltipPosition.spotlightLeft,
              width: currentStep === 3 ? '120px' : '32px',
              height: currentStep === 3 ? '40px' : '32px',
              borderRadius: '6px',
              pointerEvents: 'none',
              transform: 'translate(-50%, -50%)',
              border: '2px solid rgba(255, 255, 255, 1)',
              boxShadow: `
                0 0 15px rgba(255, 255, 255, 0.9),
                0 0 25px rgba(255, 255, 255, 0.4),
                0 0 35px rgba(255, 255, 255, 0.2)
              `
            }}
          />

          {/* Render spotlight components when needed */}
          {currentStep === 3 && <SpotlightHighlightsButton />}
          {currentStep === 4 && <SpotlightBookmarkButton />}

          {/* Tooltip */}
          <div 
            className="fixed z-[70] pointer-events-none px-4 sm:px-0"
            style={{ 
              top: tooltipPosition.top,
              left: tooltipPosition.left,
              transform: 'translateX(-50%)',
              transition: 'all 0.3s ease',
              width: '100%',
              maxWidth: '300px'
            }}
          >
            <div className="bg-white rounded-xl shadow-xl p-4 sm:p-6 pointer-events-auto">
              <div className="flex gap-3 mb-3">
                <div className="text-xl flex-shrink-0">{currentTip.emoji}</div>
                <p className="text-gray-600 text-sm sm:text-base pt-1">
                  {currentTip.content}
                </p>
              </div>
              <div className="flex justify-between">
                {currentStep > 1 && (
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      setCurrentStep(currentStep - 1);
                    }}
                    onTouchStart={(e) => {
                      e.preventDefault();
                      setCurrentStep(currentStep - 1);
                    }}
                    className="p-1.5 rounded-full hover:bg-gray-100 text-gray-600 transition-colors"
                    aria-label="Previous tip"
                  >
                    <ChevronLeft size={20} />
                  </button>
                )}
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    handleNext();
                  }}
                  onTouchStart={(e) => {
                    e.preventDefault();
                    handleNext();
                  }}
                  className="p-1.5 rounded-full hover:bg-gray-100 text-gray-600 transition-colors ml-auto"
                  aria-label="Next tip"
                >
                  <ChevronRight size={20} />
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
} 