import React, { useState, useEffect } from 'react';
import { ChevronDown, ChevronUp, Plus, X, Save, GripVertical } from 'lucide-react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { LEAGUE_CHANNELS, LEAGUES } from '../config/leagues';
import { Link } from 'react-router-dom';

export default function ChannelsPage() {
  const [expandedLeagues, setExpandedLeagues] = useState({});
  const [expandedSubCategories, setExpandedSubCategories] = useState({});
  const [channelSettings, setChannelSettings] = useState({});
  const [isDirty, setIsDirty] = useState(false);

  useEffect(() => {
    // Load saved channel settings from localStorage or use defaults
    const savedSettings = localStorage.getItem('channelSettings');
    if (savedSettings) {
      setChannelSettings(JSON.parse(savedSettings));
    } else {
      setChannelSettings(LEAGUE_CHANNELS);
    }
  }, []);

  const toggleLeague = (league) => {
    setExpandedLeagues(prev => ({
      ...prev,
      [league]: !prev[league]
    }));
  };

  const toggleSubCategory = (sportKey, categoryKey) => {
    setExpandedSubCategories(prev => ({
      ...prev,
      [`${sportKey}-${categoryKey}`]: !prev[`${sportKey}-${categoryKey}`]
    }));
  };

  const handleDragEnd = (result, league) => {
    if (!result.destination) return;
    
    const items = Array.from(channelSettings[league].channels);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    
    setChannelSettings(prev => ({
      ...prev,
      [league]: {
        ...prev[league],
        channels: items
      }
    }));
    setIsDirty(true);
  };

  const addChannel = (league) => {
    setChannelSettings(prev => ({
      ...prev,
      [league]: {
        ...prev[league],
        channels: [...prev[league].channels, '']
      }
    }));
    setIsDirty(true);
  };

  const removeChannel = (league, index) => {
    setChannelSettings(prev => ({
      ...prev,
      [league]: {
        ...prev[league],
        channels: prev[league].channels.filter((_, i) => i !== index)
      }
    }));
    setIsDirty(true);
  };

  const updateChannel = (league, index, value) => {
    setChannelSettings(prev => ({
      ...prev,
      [league]: {
        ...prev[league],
        channels: prev[league].channels.map((channel, i) => 
          i === index ? value : channel
        )
      }
    }));
    setIsDirty(true);
  };

  const updateSearchParam = (league, value) => {
    setChannelSettings(prev => ({
      ...prev,
      [league]: {
        ...prev[league],
        searchParam: value
      }
    }));
    setIsDirty(true);
  };

  const saveChanges = () => {
    localStorage.setItem('channelSettings', JSON.stringify(channelSettings));
    setIsDirty(false);
  };

  const resetToDefaults = () => {
    setChannelSettings(LEAGUE_CHANNELS);
    localStorage.removeItem('channelSettings');
    setIsDirty(true);
  };

  // Group leagues by sport and category
  const groupedSettings = Object.entries(channelSettings).reduce((acc, [leagueName, settings]) => {
    const league = Object.values(LEAGUES).find(l => l.name === leagueName);
    if (!league) return acc;

    const sport = league.sport.charAt(0).toUpperCase() + league.sport.slice(1);
    
    if (!acc[sport]) {
      acc[sport] = {
        name: sport,
        leagues: {}
      };
    }

    if (sport === 'Soccer' && league.category) {
      if (!acc[sport].subCategories) {
        acc[sport].subCategories = {};
      }
      const category = league.category.charAt(0).toUpperCase() + league.category.slice(1);
      if (!acc[sport].subCategories[category]) {
        acc[sport].subCategories[category] = {
          name: category,
          leagues: {}
        };
      }
      acc[sport].subCategories[category].leagues[leagueName] = settings;
    } else {
      acc[sport].leagues[leagueName] = settings;
    }

    return acc;
  }, {});

  return (
    <div className="max-w-4xl mx-auto py-8 px-0 sm:px-4">
      <div className="bg-white rounded-xl shadow-sm p-6">
        <div className="flex items-center justify-between mb-6">
          <h1 className="text-2xl font-bold">Channel Settings</h1>
          <div className="space-x-2 sm:space-x-4">
            <button
              onClick={resetToDefaults}
              className="px-3 sm:px-4 py-2 text-gray-600 hover:bg-gray-100 rounded"
            >
              Reset
            </button>
            <button
              onClick={saveChanges}
              disabled={!isDirty}
              className={`px-3 sm:px-4 py-2 rounded ${
                isDirty
                  ? 'bg-blue-600 text-white hover:bg-blue-700'
                  : 'bg-gray-100 text-gray-400 cursor-not-allowed'
              }`}
            >
              Save
            </button>
          </div>
        </div>

        <div className="mb-6 bg-amber-50 border border-amber-200 rounded-lg p-4">
          <p className="text-amber-700">
            <strong>Important:</strong> Channel names must match YouTube exactly (e.g., "ESPN FC" not "ESPN Football").{' '}
            <Link to="/about" className="underline hover:text-amber-800">
              Read more about channel settings in our guide
            </Link>
          </p>
        </div>

        <div className="space-y-4">
          {Object.entries(groupedSettings).map(([sportName, sportGroup]) => (
            <div key={sportName} className="border rounded-lg overflow-hidden">
              <button
                onClick={() => toggleLeague(sportName)}
                className="w-full px-4 py-3 bg-gray-50 hover:bg-gray-100 flex items-center"
              >
                <span className="font-medium">{sportName}</span>
              </button>

              {expandedLeagues[sportName] && (
                <div className="p-4 space-y-4">
                  {sportName === 'Soccer' && sportGroup.subCategories ? (
                    Object.entries(sportGroup.subCategories).map(([category, { name, leagues }]) => (
                      <div key={category} className="border rounded-lg overflow-hidden">
                        <button
                          onClick={() => toggleSubCategory(sportName, category)}
                          className="w-full px-4 py-2 bg-gray-50 hover:bg-gray-100 flex items-center"
                        >
                          <span className="font-medium">{name}</span>
                        </button>

                        {expandedSubCategories[`${sportName}-${category}`] && (
                          <div className="p-2 space-y-2">
                            {Object.entries(leagues).map(([leagueName, settings]) => (
                              <LeagueSettings
                                key={leagueName}
                                leagueName={leagueName}
                                settings={settings}
                                onUpdateSearchParam={updateSearchParam}
                                onAddChannel={addChannel}
                                onRemoveChannel={removeChannel}
                                onUpdateChannel={updateChannel}
                                onDragEnd={handleDragEnd}
                              />
                            ))}
                          </div>
                        )}
                      </div>
                    ))
                  ) : (
                    Object.entries(sportGroup.leagues).map(([leagueName, settings]) => (
                      <LeagueSettings
                        key={leagueName}
                        leagueName={leagueName}
                        settings={settings}
                        onUpdateSearchParam={updateSearchParam}
                        onAddChannel={addChannel}
                        onRemoveChannel={removeChannel}
                        onUpdateChannel={updateChannel}
                        onDragEnd={handleDragEnd}
                      />
                    ))
                  )}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

// Separate component for league settings
function LeagueSettings({ 
  leagueName, 
  settings, 
  onUpdateSearchParam, 
  onAddChannel, 
  onRemoveChannel, 
  onUpdateChannel, 
  onDragEnd 
}) {
  const [expanded, setExpanded] = useState(false);
  const league = Object.values(LEAGUES).find(l => l.name === leagueName);

  return (
    <div className="border rounded-lg overflow-hidden">
      <button
        onClick={() => setExpanded(!expanded)}
        className="w-full px-4 py-3 bg-gray-50 hover:bg-gray-100 flex items-center"
      >
        <div className="flex items-center gap-2">
          {league?.logo ? (
            <img src={league.logo} alt="" className="w-5 h-5 object-contain" />
          ) : (
            <span className="text-xl">{league?.emoji}</span>
          )}
          <span className="font-medium">{leagueName}</span>
        </div>
      </button>

      {expanded && (
        <div className="p-4 space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Search Parameter
            </label>
            <input
              type="text"
              value={settings.searchParam}
              onChange={(e) => onUpdateSearchParam(leagueName, e.target.value)}
              className="w-full px-3 py-2 border rounded-md"
              placeholder="Enter search parameter..."
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Channels (drag to reorder by priority)
            </label>
            <DragDropContext onDragEnd={(result) => onDragEnd(result, leagueName)}>
              <Droppable droppableId={`${leagueName}-channels`}>
                {(provided) => (
                  <div {...provided.droppableProps} ref={provided.innerRef} className="space-y-2">
                    {settings.channels.map((channel, index) => (
                      <Draggable
                        key={`${leagueName}-channel-${index}`}
                        draggableId={`${leagueName}-channel-${index}`}
                        index={index}
                      >
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            className="flex items-center gap-2"
                          >
                            <div
                              {...provided.dragHandleProps}
                              className="p-2 text-gray-400 hover:text-gray-600 cursor-grab"
                            >
                              <GripVertical className="h-5 w-5" />
                            </div>
                            <input
                              type="text"
                              value={channel}
                              onChange={(e) => onUpdateChannel(leagueName, index, e.target.value)}
                              className="flex-1 px-3 py-2 border rounded-md"
                              placeholder="Enter channel name..."
                            />
                            <button
                              onClick={() => onRemoveChannel(leagueName, index)}
                              className="p-2 text-red-500 hover:bg-red-50 rounded-md"
                            >
                              <X className="h-5 w-5" />
                            </button>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
            <button
              onClick={() => onAddChannel(leagueName)}
              className="mt-2 flex items-center gap-2 text-blue-600 hover:text-blue-700"
            >
              <Plus className="h-4 w-4" />
              <span>Add Channel</span>
            </button>
          </div>
        </div>
      )}
    </div>
  );
}