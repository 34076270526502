import React, { useState, useEffect, useMemo } from 'react';
import { sportsApi } from '../services/sportsApi';
import { youtubeApi } from '../services/youtubeApi';
import { Play, X, Plus, ChevronUp, RotateCcw } from 'lucide-react';
import { Menu } from '@headlessui/react';
import QueueButton from './QueueButton';
import { useNavigate } from 'react-router-dom';
import SpoilerModeToggle from './SpoilerModeToggle';
import { LEAGUES, getLeaguesByGroup } from '../config/leagues';
import LoadingSpinner from './LoadingSpinner';
import { useApp } from '../contexts/AppContext';

const SPORTS_GROUPS = getLeaguesByGroup();

const DUMMY_GAMES = [
  {
    id: 'dummy-1',
    league: 'NBA',
    time: '7:30 PM',
    status: 'In Progress',
    statusDetail: '4th Qtr - 5:42',
    rawDate: new Date().toISOString(),
    homeTeam: {
      id: 'nba-1',
      name: 'Los Angeles Lakers',
      logo: 'https://a.espncdn.com/i/teamlogos/nba/500/lal.png',
      score: 112
    },
    awayTeam: {
      id: 'nba-2',
      name: 'Golden State Warriors',
      logo: 'https://a.espncdn.com/i/teamlogos/nba/500/gs.png',
      score: 108
    }
  },
  {
    id: 'dummy-2',
    league: 'Premier League',
    time: '3:00 PM',
    status: 'Final',
    rawDate: new Date().toISOString(),
    homeTeam: {
      id: 'soccer-eng.1-361',
      name: 'Arsenal',
      logo: 'https://a.espncdn.com/i/teamlogos/soccer/500/359.png',
      score: 2
    },
    awayTeam: {
      id: 'soccer-eng.1-382',
      name: 'Manchester United',
      logo: 'https://a.espncdn.com/i/teamlogos/soccer/500/360.png',
      score: 1
    },
    hasHighlights: true
  }
];

const getShortTeamName = (fullName, league) => {
  // Special handling for international teams
  if (league.includes('World Cup') || 
      league.includes('Euro') || 
      league.includes('Copa America') || 
      league.includes('Gold Cup') || 
      league.includes('Asian Cup') || 
      league.includes('Africa Cup')) {
    return fullName; // Keep full country names
  }
  
  // Special handling for soccer teams and college teams
  if (['Premier League', 'La Liga', 'Serie A', 'MLS', 'Champions League', 'Europa League', 'Bundesliga'].includes(league) ||
      fullName.includes('University') || fullName.includes('State') || fullName.includes('College')) {
    // Return full name for soccer teams and college teams
    return fullName;
  }

  // Handle common city prefixes for other sports
  const cityPrefixes = ['New York', 'Los Angeles', 'San Francisco', 'San Diego', 'New Orleans', 'Golden State'];
  let shortName = fullName;
  
  // Remove city prefix if present
  cityPrefixes.forEach(prefix => {
    if (shortName.startsWith(prefix)) {
      shortName = shortName.replace(prefix, '').trim();
    }
  });

  // If name still has multiple words, take the last word (usually the mascot)
  const words = shortName.split(' ');
  if (words.length > 1) {
    return words[words.length - 1];
  }
  
  return shortName;
};

const formatRelativeDate = (rawDate) => {
  // Create dates using local timezone
  const now = new Date();
  const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
  const tomorrow = new Date(today);
  tomorrow.setDate(today.getDate() + 1);
  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);

  // Parse the game date and set to midnight in local timezone
  const gameDate = new Date(rawDate);
  const gameDateMidnight = new Date(
    gameDate.getFullYear(),
    gameDate.getMonth(),
    gameDate.getDate()
  );

  // Compare timestamps for accurate date matching
  if (gameDateMidnight.getTime() === today.getTime()) {
    return 'Today';
  } else if (gameDateMidnight.getTime() === yesterday.getTime()) {
    return 'Yesterday';
  } else if (gameDateMidnight.getTime() === tomorrow.getTime()) {
    return 'Tomorrow';
  } else {
    // For other dates, use local date string
    return gameDate.toLocaleDateString('en-US', { 
      weekday: 'long',
      month: 'long', 
      day: 'numeric'
    });
  }
};

// Add this helper function near the top of the file
const normalizeTeamId = (id, league) => {
  if (!id) return id;
  
  // If the ID already has the correct format, return it
  if (id.includes('basketball-nba-') || id.startsWith('wnba-')) {
    return id;
  }

  // For NBA games, prefix with basketball-nba-
  if (league === 'NBA') {
    return `basketball-nba-${id}`;
  }
  
  // For WNBA games, prefix with wnba-
  if (league === 'WNBA') {
    return `wnba-${id}`;
  }

  return id;
};

function GamesDisplay({ 
  following, 
  initialGames = null,
  showFilters = true,
  bypassInternalFilters = false
}) {
  const { 
    defaultFilters, 
    activeFilters, 
    setActiveFilters, 
    spoilerMode,
    isAuthenticated,
    sessionFilters,
    setSessionFilters,
    isLoading: contextLoading
  } = useApp();
  const [games, setGames] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedView, setSelectedView] = useState('my-teams');
  const [dateRange, setDateRange] = useState('recent');
  const [showHighlights, setShowHighlights] = useState(false);
  const [highlightVideoId, setHighlightVideoId] = useState(null);
  const [dateFilter, setDateFilter] = useState('all');
  const [loadingHighlights, setLoadingHighlights] = useState({});
  const [showSpoilerOverlay, setShowSpoilerOverlay] = useState(true);
  const [videoStarted, setVideoStarted] = useState(false);
  const [fullscreenTimeout, setFullscreenTimeout] = useState(null);
  const navigate = useNavigate();
  const [isFilterMenuOpen, setIsFilterMenuOpen] = useState(false);
  const [isFilterSectionVisible, setIsFilterSectionVisible] = useState(false);
  const [showDelayedSpinner, setShowDelayedSpinner] = useState(false);
  const [isInitializing, setIsInitializing] = useState(true);

  // Add a ref to track initial mount
  const isFirstMount = React.useRef(true);

  // Add a guard for the initial render
  if (contextLoading || !activeFilters) {
    return (
      <div className="bg-white rounded-xl shadow-sm p-6 sm:p-8 text-center">
        <LoadingSpinner />
      </div>
    );
  }

  // Update the filter initialization effect
  useEffect(() => {
    if (contextLoading) return; // Don't initialize if context is still loading
    
    setIsInitializing(true);
    
    try {
      const effectivelyAuthenticated = isAuthenticated || 
        (defaultFilters && Object.keys(defaultFilters).length > 0) ||
        (following && following.length > 0);

      const newFilters = {
        date: ['today', 'recent'],
        leagues: []
      };

      if (sessionFilters) {
        setActiveFilters(sessionFilters);
      } else if (effectivelyAuthenticated && defaultFilters?.leagues) {
        newFilters.leagues = [...defaultFilters.leagues];
        setActiveFilters(newFilters);
        setSessionFilters(newFilters);
      } else {
        newFilters.leagues = ['my-teams'];
        setActiveFilters(newFilters);
        setSessionFilters(newFilters);
      }
    } catch (err) {
      setActiveFilters({
        date: ['today', 'recent'],
        leagues: []
      });
    } finally {
      setIsInitializing(false);
    }
  }, [isAuthenticated, defaultFilters, following, sessionFilters, contextLoading]);

  // Keep the separate effect for handling filter changes
  useEffect(() => {
    if (!isInitializing && activeFilters) {
      if (activeFilters.date.length > 0 || activeFilters.leagues.length > 0) {
        loadGames();
      }
    }
  }, [activeFilters, isInitializing]);

  const handleFilterChange = (newFilters) => {
    setActiveFilters(newFilters);
    setSessionFilters(newFilters);
  };

  // Define filter options
  const dateFilters = [
    { id: 'recent', label: 'Recent', emoji: '🔙' },
    { id: 'upcoming', label: 'Upcoming', emoji: '🔜' }
  ];

  const handleHighlightClick = async (gameId, game) => {
    try {
      setLoadingHighlights(prev => ({ ...prev, [gameId]: true }));
      
      const result = await youtubeApi.getHighlightVideo(
        game.homeTeam.name,
        game.awayTeam.name,
        game.rawDate,
        game.league
      );

      const youtubeUrl = result.type === 'direct' 
        ? `https://www.youtube.com/watch?v=${result.videoId}`
        : `https://www.youtube.com/results?search_query=${encodeURIComponent(result.searchQuery)}`;

      try {
        window.location.href = youtubeUrl;
      } catch (openError) {
        try {
          const link = document.createElement('a');
          link.href = youtubeUrl;
          link.rel = 'noopener noreferrer';
          link.target = '_blank';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } catch (anchorError) {
          alert(`Unable to open automatically. Please copy this URL: ${youtubeUrl}`);
        }
      }

    } catch (error) {
      alert(`Error: ${error.message}`);
    } finally {
      setLoadingHighlights(prev => ({ ...prev, [gameId]: false }));
    }
  };

  // Add this effect to handle dummy games
  useEffect(() => {
    if (bypassInternalFilters) {
      setGames(DUMMY_GAMES);
      setLoading(false);
    } else if (initialGames) {
      setGames(initialGames);
      setLoading(false);
    } else {
      loadGames();
    }
  }, [initialGames, bypassInternalFilters]);

  const loadGames = async () => {
    if (bypassInternalFilters) {
      setGames(DUMMY_GAMES);
      setLoading(false);
      return;
    }

    try {
      setLoading(true);
      setError(null);

      let fetchedGames = [];
      
      if (activeFilters.date.length > 0 && activeFilters.leagues.length > 0) {
        const boundaries = activeFilters.date.reduce((acc, filter) => {
          const now = new Date();
          const todayStart = new Date(now.getFullYear(), now.getMonth(), now.getDate());
          const todayEnd = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59, 999);
          
          switch (filter) {
            case 'recent':
              acc.push({
                start: new Date(todayStart.getTime() - (7 * 24 * 60 * 60 * 1000)), // 7 days ago
                end: todayEnd // Include today
              });
              break;
            case 'upcoming':
              acc.push({
                start: todayStart, // Start from beginning of today
                end: new Date(todayStart.getTime() + (7 * 24 * 60 * 60 * 1000)) // 7 days ahead from start of today
              });
              break;
          }
          return acc;
        }, []);

        // Only fetch if we have valid filters
        if (activeFilters.date.length > 0 && activeFilters.leagues.length > 0) {
          if (initialGames) {
            fetchedGames = initialGames;
          } else {
            const hasMyTeamsFilter = activeFilters.leagues.includes('my-teams');
            const leagueFilters = activeFilters.leagues.filter(id => id !== 'my-teams');
            let promises = [];

            // Calculate the maximum days needed for past and future based on selected filters
            const apiParams = {
              past: activeFilters.date.includes('recent') ? 7 : 0,
              future: activeFilters.date.includes('upcoming') ? 7 : 1 // Always include at least today
            };

            // Always include today if it's selected
            if (activeFilters.date.includes('today')) {
              apiParams.past = Math.max(apiParams.past, 1);
              apiParams.future = Math.max(apiParams.future, 1);
            }

            // Fetch games based on calculated parameters
            if (hasMyTeamsFilter && following.length > 0) {
              const teamPromises = following.map(teamId => {
                // Parse the team ID
                const parts = teamId.split('-');
                const sport = parts[0];
                
                return sportsApi.getTeamGames(teamId, sport, apiParams);
              });
              promises.push(...teamPromises);
            }

            if (leagueFilters.length > 0) {
              const leaguePromises = leagueFilters.map(leagueId => 
                sportsApi.getLeagueGames(leagueId, apiParams)
              );
              promises.push(...leaguePromises);
            }

            if (promises.length > 0) {
              const results = await Promise.all(promises);
              fetchedGames = results
                .flat()
                .filter((game, index, self) => 
                  index === self.findIndex(g => g.id === game.id)
                );
            }
          }

          // Filter games based on all selected date ranges
          fetchedGames = fetchedGames.filter(game => {
            const gameDate = new Date(game.rawDate);
            return boundaries.some(({ start, end }) => 
              gameDate >= start && gameDate <= end
            );
          });
        }
      }

      setGames(fetchedGames);
    } catch (err) {
      setError('Error loading games');
    } finally {
      setLoading(false);
    }
  };

  const filteredGames = useMemo(() => {
    if (!initialGames && !games) return [];
    
    if (bypassInternalFilters && initialGames) {
      return initialGames;
    }

    let filtered = initialGames || games;

    if (!filtered) return [];

    // Date filtering
    if (dateFilter !== 'all') {
      const now = new Date();
      filtered = filtered.filter(game => {
        if (!game?.date) return false;
        
        const gameDate = new Date(game.date);
        switch (dateFilter) {
          case '7days':
            return gameDate > now;
          case 'pastWeek':
            const weekAgo = new Date(now.getTime() - 7 * 24 * 60 * 60 * 1000);
            return gameDate >= weekAgo && gameDate <= now;
          case 'pastMonth':
            const monthAgo = new Date(now.getTime() - 30 * 24 * 60 * 60 * 1000);
            return gameDate >= monthAgo && gameDate <= now;
          default:
            return true;
        }
      });
    }

    return filtered;
  }, [initialGames, bypassInternalFilters, dateFilter, games]);

  // Modify the gamesByDate calculation to handle dummy games
  const gamesByDate = useMemo(() => {
    if (bypassInternalFilters) {
      return {
        [new Date().toISOString()]: DUMMY_GAMES
      };
    }

    return games.reduce((acc, game) => {
      // Convert to local date at midnight
      const gameDate = new Date(game.rawDate);
      const localDate = new Date(
        gameDate.getFullYear(),
        gameDate.getMonth(),
        gameDate.getDate()
      ).toISOString();

      if (!acc[localDate]) {
        acc[localDate] = [];
      }
      acc[localDate].push(game);
      return acc;
    }, {});
  }, [games, bypassInternalFilters]);

  // Add a new component for the highlights button with loading state
  const HighlightsButton = ({ onClick, loading, gameId }) => (
    <button
      onClick={onClick}
      disabled={loading}
      data-highlights-button={gameId}
      className={`mt-2 px-2 py-1 rounded-md text-blue-600 hover:bg-blue-100 mx-auto flex items-center gap-1 text-xs 
        ${loading ? 'bg-gray-100' : 'bg-blue-50'} z-[40]`}
      title="Watch Highlights"
    >
      {loading ? (
        <span className="animate-pulse">Loading...</span>
      ) : (
        <>
          <Play size={14} />
          <span>Highlights</span>
        </>
      )}
    </button>
  );

  // Add this effect to handle keyboard shortcuts
  useEffect(() => {
    const handleKeyPress = (e) => {
      if (showHighlights && e.key.toLowerCase() === 'f') {
        const iframe = document.querySelector('.highlight-video-iframe');
        if (iframe) {
          if (document.fullscreenElement) {
            document.exitFullscreen();
          } else if (iframe.requestFullscreen) {
            iframe.requestFullscreen();
          } else if (iframe.webkitRequestFullscreen) { // Safari
            iframe.webkitRequestFullscreen();
          }
        }
      }
    };

    window.addEventListener('keydown', handleKeyPress);
    return () => window.removeEventListener('keydown', handleKeyPress);
  }, [showHighlights]);

  const renderGame = (game) => {
    // Determine sport and league from game data
    const getSportAndLeague = (game) => {
      // Extract from game.sport or game.league if available
      if (game.sport && game.league) {
        return {
          sport: game.sport.toLowerCase(),
          league: game.league.toLowerCase()
        };
      }
      
      // Fallback: Determine from game ID pattern
      const gameId = game.id || game.gameId;
      if (gameId.startsWith('401')) {
        return { sport: 'basketball', league: 'nba' };
      }
      if (gameId.startsWith('720')) {
        return { sport: 'hockey', league: 'nhl' };
      }
      // Add more patterns as needed
      
      return { sport: 'unknown', league: 'unknown' };
    };

    const { sport, league } = getSportAndLeague(game);

    return (
      <div key={game.id || game.gameId} className="game-card">
        <div className="absolute top-2 right-2 sm:right-3">
          <QueueButton 
            gameId={game.id} 
            data-bookmark-button={game.id}
            className="z-[40]"
          />
        </div>
        {/* ... other game display elements ... */}
      </div>
    );
  };

  const handleTeamClick = (teamId) => {
    navigate(`/team/${teamId}`);
  };

  // Update the getMenuItems function
  const getMenuItems = () => {
    let groupedLeagues = {
      'My Teams': [{
        id: 'my-teams',
        name: 'My Teams',
        emoji: '⭐',
        displayPriority: -1
      }]
    };

    // Group leagues by sport
    Object.values(LEAGUES).forEach(league => {
      const sport = league.sport.charAt(0).toUpperCase() + league.sport.slice(1);
      
      // Initialize sport category if it doesn't exist
      if (!groupedLeagues[sport]) {
        groupedLeagues[sport] = [];
      }

      // For soccer, split into International and Club categories
      if (league.sport === 'soccer') {
        const categoryName = league.category === 'international' ? 'International' : 'Club';
        if (!groupedLeagues[categoryName]) {
          groupedLeagues[categoryName] = [];
        }
        groupedLeagues[categoryName].push(league);
      } else {
        groupedLeagues[sport].push(league);
      }
    });

    // Sort leagues within each sport by displayPriority
    Object.keys(groupedLeagues).forEach(sport => {
      if (sport !== 'My Teams') {
        groupedLeagues[sport].sort((a, b) => (a.displayPriority || 999) - (b.displayPriority || 999));
      }
    });

    return groupedLeagues;
  };

  // Replace the existing filter UI with this new section
  const renderFilterSection = () => {
    if (!activeFilters?.leagues) return null;
    
    return (
      <div className="px-4 sm:px-0 mb-4">
        <div className="flex flex-wrap items-center gap-2 mb-4">
          {/* Only show league filter tags */}
          {activeFilters.leagues.map(leagueId => {
            if (leagueId === 'my-teams') {
              return (
                <span 
                  key={leagueId}
                  className="inline-flex items-center gap-1 px-3 py-1 rounded-full text-sm bg-blue-100 text-blue-800"
                >
                  <span>⭐</span>
                  My Teams
                  <button
                    onClick={() => removeLeagueFilter(leagueId)}
                    className="ml-1 hover:text-blue-900"
                  >
                    <X size={14} />
                  </button>
                </span>
              );
            }

            const league = Object.values(LEAGUES).find(l => l.id === leagueId);
            return (
              <span 
                key={leagueId}
                className="inline-flex items-center gap-1 px-3 py-1 rounded-full text-sm bg-blue-100 text-blue-800"
              >
                {league?.logo ? (
                  <img src={league.logo} alt="" className="w-4 h-4 object-contain" />
                ) : (
                  <span>{league?.emoji}</span>
                )}
                {league?.shortName || league?.name}
                <button
                  onClick={() => removeLeagueFilter(leagueId)}
                  className="ml-1 hover:text-blue-900"
                >
                  <X size={14} />
                </button>
              </span>
            );
          })}

          {/* Filter controls */}
          <div className="flex items-center gap-2">
            <button
              data-filter-button
              onClick={() => setIsFilterSectionVisible(prev => !prev)}
              className="inline-flex items-center justify-center w-7 h-7 rounded-md text-gray-500 hover:bg-gray-100"
            >
              {isFilterSectionVisible ? (
                <ChevronUp 
                  size={20}
                  className="transform transition-transform duration-200"
                />
              ) : (
                <Plus size={20} />
              )}
            </button>

            {/* Reset button - show if any filters are active */}
            {(activeFilters.leagues.length > 0 || activeFilters.date.length > 0) && (
              <button
                onClick={() => {
                  const defaultFilters = { 
                    date: ['today', 'recent'], // Always include these date filters
                    leagues: [] 
                  };
                  setActiveFilters(defaultFilters);
                  setSessionFilters(defaultFilters);
                }}
                className="inline-flex items-center justify-center w-7 h-7 rounded-md text-gray-500 hover:bg-gray-100 translate-y-[0.5px]"
                title="Reset filters"
              >
                <RotateCcw size={18} />
              </button>
            )}
          </div>
        </div>

        {/* Filter Tag Bank - Only visible when toggled */}
        {isFilterSectionVisible && (
          <div className="space-y-0.5 bg-gray-100 rounded-lg mt-2 border border-gray-200 shadow-sm">
            {/* Date Filters Section with distinct styling */}
            <div className="p-4 bg-white rounded-t-lg">
              <div className="flex flex-wrap gap-2">
                {dateFilters.map(filter => {
                  const isSelected = activeFilters.date.includes(filter.id);
                  
                  return (
                    <button
                      key={filter.id}
                      onClick={() => toggleDateFilter(filter.id)}
                      className={`
                        px-3 py-1 rounded-full text-sm inline-flex items-center gap-1
                        transition-colors duration-150
                        ${isSelected
                          ? 'bg-green-100 text-green-800 border border-green-200'
                          : 'bg-gray-50 text-gray-600 hover:bg-green-50 border border-gray-200'
                        }
                      `}
                    >
                      <span>{filter.emoji}</span>
                      {filter.label}
                    </button>
                  );
                })}
              </div>
            </div>

            {/* League Filters Section */}
            <div className="p-4 bg-white rounded-b-lg">
              {Object.entries(getMenuItems()).map(([sportName, leagues]) => (
                <div key={sportName} className="mt-4 first:mt-0">
                  <div className="flex justify-between items-center mb-2">
                    <h3 className="text-xs font-medium text-gray-500">{sportName}</h3>
                    {leagues.length > 1 && (
                      <button
                        onClick={() => {
                          const leagueIds = leagues.map(league => league.id);
                          const allSelected = leagueIds.every(id => 
                            activeFilters.leagues.includes(id)
                          );
                          
                          setActiveFilters(prev => ({
                            ...prev,
                            leagues: allSelected
                              ? prev.leagues.filter(id => !leagueIds.includes(id))
                              : [...new Set([...prev.leagues, ...leagueIds])]
                          }));
                        }}
                        className="text-xs text-blue-600 hover:text-blue-800"
                      >
                        {leagues.every(league => 
                          activeFilters.leagues.includes(league.id)
                        ) ? 'Deselect All' : 'Select All'}
                      </button>
                    )}
                  </div>
                  <div className="flex flex-wrap gap-2">
                    {leagues.map(league => (
                      <button
                        key={league.id}
                        onClick={() => toggleLeagueFilter(league.id)}
                        className={`
                          inline-flex items-center gap-1 px-3 py-1 rounded-full text-sm
                          ${defaultFilters.leagues.includes(league.id)
                            ? 'bg-blue-100 text-blue-800'
                            : 'bg-gray-50 text-gray-600 hover:bg-gray-100' // Changed from bg-white or bg-gray-50
                          }
                        `}
                      >
                        {league.logo ? (
                          <img src={league.logo} alt="" className="w-4 h-4 object-contain" />
                        ) : (
                          <span>{league.emoji}</span>
                        )}
                        {league.shortName || league.name}
                      </button>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    );
  };

  // Add helper functions
  const toggleLeagueFilter = (leagueId) => {
    const newFilters = {
      ...activeFilters,
      leagues: activeFilters.leagues.includes(leagueId)
        ? activeFilters.leagues.filter(id => id !== leagueId)
        : [...activeFilters.leagues, leagueId]
    };
    setActiveFilters(newFilters);
    setSessionFilters(newFilters);
  };

  const removeLeagueFilter = (leagueId) => {
    const newFilters = {
      ...activeFilters,
      leagues: activeFilters.leagues.filter(id => id !== leagueId)
    };
    setActiveFilters(newFilters);
    setSessionFilters(newFilters);
  };

  // Add these helper functions
  const toggleDateFilter = (dateId) => {
    const newFilters = {
      ...activeFilters,
      date: activeFilters.date.includes(dateId) ? activeFilters.date : [dateId]
    };
    setActiveFilters(newFilters);
    setSessionFilters(newFilters);
  };

  const removeDateFilter = (dateId) => {
    const newFilters = {
      ...activeFilters,
      date: activeFilters.date.filter(id => id !== dateId)
    };
    setActiveFilters(newFilters);
    setSessionFilters(newFilters);
  };

  // Add this useEffect to handle the delayed spinner
  useEffect(() => {
    let timeout;
    if (loading && !initialGames) {
      // Only show spinner if loading takes longer than 500ms
      timeout = setTimeout(() => {
        setShowDelayedSpinner(true);
      }, 500);
    } else {
      setShowDelayedSpinner(false);
    }

    return () => clearTimeout(timeout);
  }, [loading, initialGames]);

  // At the start of the component
  useEffect(() => {
  }, [following, games, activeFilters, bypassInternalFilters]);

  // Add a guard at the start of the render to show dummy games
  if (bypassInternalFilters) {
    const dummyGamesByDate = {
      [new Date().toISOString()]: DUMMY_GAMES
    };

    return (
      <div className="-mx-6 sm:mx-4 lg:mx-8">
        {Object.entries(dummyGamesByDate).map(([date, dateGames]) => (
          <div key={date} className="bg-white rounded-xl shadow-sm overflow-hidden">
            <div className="px-6 py-4 bg-gray-50 border-b">
              <h2 className="font-medium text-gray-900">
                Today
              </h2>
            </div>
            <div className="divide-y">
              {dateGames.map(game => {
                console.log('Game:', {
                  id: game.id,
                  status: game.status,
                  scores: {
                    home: game.homeTeam?.score,
                    away: game.awayTeam?.score
                  }
                });
                
                const isFollowingHome = isTeamFollowed(game.homeTeam.id, game.league);
                const isFollowingAway = isTeamFollowed(game.awayTeam.id, game.league);
                
                return (
                  <div 
                    key={game.id} 
                    className={`px-4 pt-4 pb-6 sm:px-6 sm:pt-5 sm:pb-7 transition-colors relative
                      ${game.status?.toLowerCase().includes('postponed') || game.status?.toLowerCase().includes('cancelled')
                        ? 'bg-gray-50/50' 
                        : ''
                      }`}
                  >
                    <div className="absolute top-2 right-2 sm:right-3">
                      <QueueButton 
                        gameId={game.id} 
                        data-bookmark-button={game.id}
                        className="z-[40]"
                      />
                    </div>

                    <div className="flex justify-between text-xs sm:text-sm text-gray-500 mb-6">
                      <span>{game.league}</span>
                      <span className="mr-6 sm:mr-8">
                        {game.status?.toLowerCase().includes('postponed') || game.status?.toLowerCase().includes('cancelled')
                          ? '---'
                          : game.time
                        }
                      </span>
                    </div>

                    {/* Teams and Score */}
                    <div className={`grid grid-cols-3 items-center gap-4 sm:grid-cols-[2fr_1fr_2fr]
                      ${(game.status?.toLowerCase().includes('postponed') || game.status?.toLowerCase().includes('cancelled'))
                        ? 'opacity-75'
                        : ''
                      }`}
                    >
                      {/* Home Team */}
                      <div className="flex flex-col items-center gap-1 text-center">
                        {game.homeTeam.logo && (
                          <img 
                            src={game.homeTeam.logo} 
                            alt=""
                            className="w-10 h-10 object-contain cursor-pointer hover:opacity-80 transition-opacity"
                            onClick={() => handleTeamClick(game.homeTeam.id)}
                          />
                        )}
                        <span className={`text-xs font-medium ${following.includes(game.homeTeam.id) ? 'text-blue-600' : ''}`}>
                          {getShortTeamName(game.homeTeam.name, game.league)}
                        </span>
                      </div>

                      {/* Score and Highlights Section */}
                      <div className="text-center">
                        {game.status?.includes('STATUS_') ? (
                          <div>
                            <div className="font-bold text-sm">
                              {spoilerMode ? (
                                <span className="text-green-600">LIVE</span>
                              ) : (
                                `${game.homeTeam.score} - ${game.awayTeam.score}`
                              )}
                            </div>
                            <div className="text-xs text-green-600">
                              {spoilerMode ? 'In Progress' : game.statusDetail}
                            </div>
                          </div>
                        ) : game.status === 'Final' ? (
                          <div>
                            <div className="font-bold text-sm">
                              {spoilerMode ? (
                                'FINAL'
                              ) : (
                                `${game.homeTeam.score} - ${game.awayTeam.score}`
                              )}
                            </div>
                            {youtubeApi.isHighlightAvailable(game) && (
                              <HighlightsButton 
                                onClick={() => handleHighlightClick(game.id, game)}
                                loading={loadingHighlights[game.id]}
                                gameId={game.id}
                              />
                            )}
                          </div>
                        ) : (
                          <div className="text-gray-500 text-sm">vs</div>
                        )}
                      </div>

                      {/* Away Team */}
                      <div className="flex flex-col items-center gap-1 text-center">
                        {game.awayTeam.logo && (
                          <img 
                            src={game.awayTeam.logo} 
                            alt=""
                            className="w-10 h-10 object-contain cursor-pointer hover:opacity-80 transition-opacity"
                            onClick={() => handleTeamClick(game.awayTeam.id)}
                          />
                        )}
                        <span className={`text-xs font-medium ${following.includes(game.awayTeam.id) ? 'text-blue-600' : ''}`}>
                          {getShortTeamName(game.awayTeam.name, game.league)}
                        </span>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        ))}
      </div>
    );
  }

  // Then modify the game comparison logic to use this helper
  const isTeamFollowed = (teamId, league) => {
    const normalizedId = normalizeTeamId(teamId, league);
    return following.includes(normalizedId);
  };

  return (
    <div className="-mx-6 sm:mx-4 lg:mx-8">
      {showFilters && renderFilterSection()}

      {(showDelayedSpinner || isInitializing) && (
        <div className="bg-white rounded-xl shadow-sm p-6 sm:p-8 text-center">
          <LoadingSpinner />
        </div>
      )}
      
      {error && (
        <div className="bg-white rounded-xl shadow-sm p-6 sm:p-8 text-center text-red-500">
          {error}
        </div>
      )}

      {!loading && !error && games.length === 0 && !bypassInternalFilters && (
        <div className="bg-white rounded-xl shadow-sm p-6 sm:p-8 text-center">
          {activeFilters.leagues.includes('my-teams') && following.length === 0 ? (
            <div className="space-y-2">
              <p className="text-gray-500">You haven't followed any teams yet!</p>
              <p className="text-sm text-gray-400">
                Follow teams to see their games appear here
              </p>
            </div>
          ) : (
            <div>
              <LoadingSpinner />
              <p className="text-gray-500 mt-4">
                {activeFilters.leagues.length === 0 && !activeFilters.date.length
                  ? "Click the + icon above to select leagues and dates"
                  : activeFilters.leagues.length > 0 && !activeFilters.date.length
                  ? "Click the + icon above to select leagues and dates"
                  : "Add filters using the + icon above to see games"}
              </p>
            </div>
          )}
        </div>
      )}

      {/* Render games grouped by date */}
      {Object.entries(gamesByDate)
        .sort(([dateA], [dateB]) => {
          if (bypassInternalFilters) {
            return 0;
          }
          
          // For the "upcoming" filter, sort with closest dates first
          if (activeFilters.date.includes('upcoming')) {
            return new Date(dateA) - new Date(dateB);
          }
          
          // For other filters, keep existing sort (most recent first)
          return new Date(dateB) - new Date(dateA);
        })
        .map(([date, dateGames], dateIndex, datesArray) => {
          // Only show date header if it's different from previous date
          const showDateHeader = dateIndex === 0 || 
            formatRelativeDate(date) !== formatRelativeDate(datesArray[dateIndex - 1][0]);

          return (
            <div key={date} className={`bg-white rounded-xl shadow-sm overflow-hidden ${dateIndex === 0 || showDateHeader ? 'mt-4' : ''}`}>
              {showDateHeader && (
                <div className="px-1 py-3 bg-gray-50 border-b">
                  <h2 className="text-sm font-medium text-gray-600">
                    {formatRelativeDate(date)}
                  </h2>
                </div>
              )}
              
              <div className="divide-y">
                {dateGames.map(game => {
                  console.log('Render state:', {
                    game: game.id,
                    spoilerMode: spoilerMode,
                    status: game.status,
                    scores: {
                      home: game.homeTeam?.score,
                      away: game.awayTeam?.score
                    }
                  });
                  
                  const isFollowingHome = isTeamFollowed(game.homeTeam.id, game.league);
                  const isFollowingAway = isTeamFollowed(game.awayTeam.id, game.league);
                  
                  return (
                    <div 
                      key={game.id} 
                      className={`px-4 pt-4 pb-6 sm:px-6 sm:pt-5 sm:pb-7 transition-colors relative
                        ${game.status?.toLowerCase().includes('postponed') || game.status?.toLowerCase().includes('cancelled')
                          ? 'bg-gray-50/50' 
                          : ''
                        }`}
                    >
                      <div className="absolute top-2 right-2 sm:right-3">
                        <QueueButton 
                          gameId={game.id} 
                          data-bookmark-button={game.id}
                          className="z-[40]"
                        />
                      </div>

                      <div className="flex justify-between text-xs sm:text-sm text-gray-500 mb-6">
                        <span>{game.league}</span>
                        <span className="mr-6 sm:mr-8">
                          {game.status?.toLowerCase().includes('postponed') || game.status?.toLowerCase().includes('cancelled')
                            ? '---'
                            : game.time
                          }
                        </span>
                      </div>

                      {/* Teams and Score */}
                      <div className={`grid grid-cols-3 items-center gap-4 sm:grid-cols-[2fr_1fr_2fr]
                        ${(game.status?.toLowerCase().includes('postponed') || game.status?.toLowerCase().includes('cancelled'))
                          ? 'opacity-75'
                          : ''
                        }`}
                      >
                        {/* Home Team */}
                        <div className="flex flex-col items-center gap-1 text-center">
                          {game.homeTeam.logo && (
                            <img 
                              src={game.homeTeam.logo} 
                              alt=""
                              className="w-10 h-10 object-contain cursor-pointer hover:opacity-80 transition-opacity"
                              onClick={() => handleTeamClick(game.homeTeam.id)}
                            />
                          )}
                          <span className={`text-xs font-medium ${following.includes(game.homeTeam.id) ? 'text-blue-600' : ''}`}>
                            {getShortTeamName(game.homeTeam.name, game.league)}
                          </span>
                        </div>

                        {/* Score and Highlights Section */}
                        <div className="text-center">
                          {game.status?.includes('STATUS_') ? (
                            <div>
                              <div className="font-bold text-sm">
                                {spoilerMode ? (
                                  <span className="text-green-600">LIVE</span>
                                ) : (
                                  `${game.homeTeam.score} - ${game.awayTeam.score}`
                                )}
                              </div>
                              <div className="text-xs text-green-600">
                                {spoilerMode ? 'In Progress' : game.statusDetail}
                              </div>
                            </div>
                          ) : game.status === 'Final' ? (
                            <div>
                              <div className="font-bold text-sm">
                                {spoilerMode ? (
                                  'FINAL'
                                ) : (
                                  `${game.homeTeam.score} - ${game.awayTeam.score}`
                                )}
                              </div>
                              {youtubeApi.isHighlightAvailable(game) && (
                                <HighlightsButton 
                                  onClick={() => handleHighlightClick(game.id, game)}
                                  loading={loadingHighlights[game.id]}
                                  gameId={game.id}
                                />
                              )}
                            </div>
                          ) : (
                            <div className="text-gray-500 text-sm">vs</div>
                          )}
                        </div>

                        {/* Away Team */}
                        <div className="flex flex-col items-center gap-1 text-center">
                          {game.awayTeam.logo && (
                            <img 
                              src={game.awayTeam.logo} 
                              alt=""
                              className="w-10 h-10 object-contain cursor-pointer hover:opacity-80 transition-opacity"
                              onClick={() => handleTeamClick(game.awayTeam.id)}
                            />
                          )}
                          <span className={`text-xs font-medium ${following.includes(game.awayTeam.id) ? 'text-blue-600' : ''}`}>
                            {getShortTeamName(game.awayTeam.name, game.league)}
                          </span>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
    </div>
  );
}
export default GamesDisplay;
