import { Link, useLocation } from 'react-router-dom';
import { useApp } from '../contexts/AppContext';
import { 
  Settings, 
  Home, 
  Star,
  ListTodo
} from 'lucide-react';
import { useState } from 'react';
import Sidebar from './Sidebar';

export default function Navigation() {
  const { currentUser, isGuest } = useApp();
  const location = useLocation();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  if (!currentUser && !isGuest) return null;

  const isActive = (path) => location.pathname === path;

  return (
    <nav className="bg-white shadow-sm">
      <div className="container mx-auto px-4 sm:px-4">
        <div className="flex flex-row items-center justify-between py-3">
          <Link to="/" className="flex items-center shrink-0">
            <img src="/Logo.png" alt="SpoilSports Logo" className="h-8 w-auto" />
          </Link>

          <div className="flex items-center gap-0.5 sm:gap-2">
            <Link
              to="/"
              className={`p-2 rounded-md ${
                isActive('/') 
                  ? 'bg-blue-100 text-blue-700' 
                  : 'text-gray-700 hover:bg-gray-100'
              }`}
              title="Feed"
            >
              <Home size={20} />
            </Link>
            <Link
              to="/follow"
              data-nav-following
              className={`p-2 rounded-md ${
                isActive('/follow') 
                  ? 'bg-blue-100 text-blue-700' 
                  : 'text-gray-700 hover:bg-gray-100'
              }`}
              title="Follow"
            >
              <Star size={20} />
            </Link>
            <Link
              to="/queue"
              data-nav-queue
              className={`p-2 rounded-md ${
                isActive('/queue') 
                  ? 'bg-blue-100 text-blue-700' 
                  : 'text-gray-700 hover:bg-gray-100'
              }`}
              title="Queue"
            >
              <ListTodo size={20} />
            </Link>

            <button
              onClick={() => setIsSidebarOpen(true)}
              className="p-2 rounded-md text-gray-700 hover:bg-gray-100"
              title="Settings"
            >
              <Settings size={20} />
            </button>
          </div>
        </div>
      </div>

      <Sidebar isOpen={isSidebarOpen} onClose={() => setIsSidebarOpen(false)} />
    </nav>
  );
} 