import React, { useState, useEffect, useMemo } from 'react';
import { ChevronDown, ChevronUp, Search, Plus, Check, X } from 'lucide-react';
import { useApp } from './contexts/AppContext';
import { useNavigate } from 'react-router-dom';
import { 
  LEAGUES, 
  getLeaguesByGroup, 
  API_BASE_URL 
} from './config/leagues';
import LoadingSpinner from './components/LoadingSpinner';

const getTeamIndicator = (team) => {
  // Soccer indicators
  if (team.sport === 'soccer') {
    if (team.league === "FIFA Women's") return '⚽ (W)';
    if (team.league === "FIFA Men's") return '⚽ (M)';
    if (team.league === "Women's Super League") return '(W)';
    return '';
  }

  // College sports indicators
  if (team.league?.toLowerCase().includes('college') || 
      team.league?.toLowerCase().includes('ncaa')) {
    const isWomens = team.league?.toLowerCase().includes("women's") || 
                    team.league?.toLowerCase().includes('w ');
    
    switch (team.sport?.toLowerCase()) {
      case 'basketball':
        return `🏀 ${isWomens ? '(W)' : '(M)'}`;
      case 'football':
        return '🏈';
      case 'baseball':
        return '⚾';
      case 'softball':
        return '⚥';
      case 'hockey':
        return '🏒';
      case 'volleyball':
        return '🏐';
      default:
        return isWomens ? '(W)' : '(M)';
    }
  }

  // Professional leagues - only show indicators for WNBA
  if (team.sport === 'basketball' && team.league === 'WNBA') {
    return '🏀 (W)';
  }

  return '';
};

function FollowingTeams({ following, teamsBySport, onFollowToggle }) {
  const [expanded, setExpanded] = useState(false);
  
  console.log('FollowingTeams props:', { following, teamsBySport });
  
  // Memoize allTeams to prevent unnecessary recalculations
  const allTeams = useMemo(() => 
    Object.values(teamsBySport)
      .flatMap(sportTeams => Object.values(sportTeams).flat())
      .filter((team, index, self) => 
        index === self.findIndex(t => t.id === team.id)
      ),
    [teamsBySport]
  );
  
  // Memoize followedTeams to prevent unnecessary recalculations
  const followedTeams = useMemo(() => 
    allTeams
      .filter(team => following.includes(team.id))
      .sort((a, b) => a.name.localeCompare(b.name)),
    [allTeams, following]
  );
  
  const isLoading = following.length > 0 && allTeams.length === 0;

  return (
    <section className="bg-white rounded-xl shadow-sm p-6">
      <div className="border rounded-lg overflow-hidden">
        <button
          onClick={() => setExpanded(!expanded)}
          className="w-full px-4 py-3 bg-gray-50 hover:bg-gray-100 flex items-center justify-between"
        >
          <div className="flex items-center gap-3">
            <span className="text-xl">⭐</span>
            <span className="font-medium">Currently Following</span>
            <span className="text-sm bg-blue-100 text-blue-700 px-2 py-0.5 rounded-full">
              {followedTeams.length}
            </span>
          </div>
          {expanded ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
        </button>

        {expanded && (
          <div className="p-4">
            {isLoading ? (
              <div className="text-center">
                <LoadingSpinner />
              </div>
            ) : followedTeams.length === 0 ? (
              <div className="text-gray-500 text-center">
                No teams followed yet. Follow some teams below!
              </div>
            ) : (
              <div className="flex flex-wrap gap-2">
                {followedTeams.map(team => {
                  console.log('Raw team data:', {
                    name: team.name,
                    league: team.league,
                    sport: team.sport,
                    fullTeam: team
                  });

                  const isCollege = team.league?.toLowerCase().includes('college') || 
                                   team.league?.toLowerCase().includes('ncaa');
                  const isWomens = team.league === "Women's College Basketball" || 
                                   team.league === "FIFA Women's" ||
                                   team.league === 'WNBA';
                  const isSoccer = team.league === "FIFA Men's" || 
                                   team.league === "FIFA Women's";
                  const sportEmoji = isCollege ? {
                    'basketball': `🏀 ${isWomens ? '(W)' : '(M)'}`,
                    'football': '🏈',
                    'baseball': '⚾',
                    'hockey': '🏒',
                  }[team.sport?.toLowerCase()] : 
                  isSoccer ? `⚽ ${isWomens ? '(W)' : '(M)'}` : null;

                  console.log('Team data:', { 
                    name: team.name, 
                    league: team.league,
                    isWomens,
                    isSoccer,
                    sportEmoji 
                  });

                  return (
                    <button
                      key={`following-${team.id}-${team.league}`}
                      className="inline-flex items-center gap-2 px-3 py-1.5 rounded-full bg-blue-50 hover:bg-blue-100 transition-colors"
                    >
                      <img 
                        src={team.logo} 
                        alt={team.name} 
                        className="w-4 h-4 object-contain"
                      />
                      <span className="text-sm text-blue-800">
                        {team.name}
                        <span className="text-xs ml-1">{getTeamIndicator(team)}</span>
                      </span>
                      <X
                        size={14}
                        className="text-blue-600 hover:text-blue-800 cursor-pointer"
                        onClick={(e) => {
                          e.stopPropagation();
                          onFollowToggle(team.id);
                        }}
                      />
                    </button>
                  );
                })}
              </div>
            )}
          </div>
        )}
      </div>
    </section>
  );
}

function SearchResults({ searchTerm, teamsBySport, following, onFollowToggle }) {
  const allTeams = Object.values(teamsBySport)
    .flatMap(sportTeams => Object.values(sportTeams).flat())
    .filter((team, index, self) => 
      index === self.findIndex(t => t.id === team.id)
    );
  
  const filteredTeams = searchTerm
    ? allTeams
        .filter(team =>
          team.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          team.abbreviation?.toLowerCase().includes(searchTerm.toLowerCase())
        )
        .sort((a, b) => a.name.localeCompare(b.name))
    : [];

  if (!searchTerm) return null;

  return (
    <div className="mt-4">
      {filteredTeams.length > 0 ? (
        <div className="flex flex-wrap gap-2">
          {filteredTeams.map(team => {
            console.log('Raw team data:', {
              name: team.name,
              league: team.league,
              sport: team.sport,
              fullTeam: team
            });

            const isCollege = team.league?.toLowerCase().includes('college') || 
                             team.league?.toLowerCase().includes('ncaa');
            const isWomens = team.league === "Women's College Basketball" || 
                             team.league === "FIFA Women's" ||
                             team.league === 'WNBA';
            const isSoccer = team.league === "FIFA Men's" || 
                             team.league === "FIFA Women's";
            const sportEmoji = isCollege ? {
              'basketball': `🏀 ${isWomens ? '(W)' : '(M)'}`,
              'football': '🏈',
              'baseball': '⚾',
              'hockey': '🏒',
            }[team.sport?.toLowerCase()] : 
            isSoccer ? `⚽ ${isWomens ? '(W)' : '(M)'}` : null;

            console.log('Detailed Team Analysis:', {
              fullName: team.name,
              league: team.league,
              nameLower: team.name.toLowerCase(),
              leagueLower: team.league?.toLowerCase(),
              isCollege,
              isWomens,
              checks: {
                nameIncludesWomen: team.name.toLowerCase().includes("women"),
                leagueIncludesWomen: team.league?.toLowerCase().includes("women"),
                nameIncludesWBasketball: team.name.toLowerCase().includes("w basketball"),
                leagueIncludesWBasketball: team.league?.toLowerCase().includes("w basketball")
              }
            });

            console.log('Team data:', { 
              name: team.name, 
              sport: team.sport, 
              isCollege, 
              isWomens, 
              sportEmoji 
            });

            return (
              <button
                key={`search-${team.id}-${team.league}`}
                onClick={() => onFollowToggle(team.id)}
                className={`
                  inline-flex items-center gap-2 px-3 py-1.5 rounded-full
                  ${following.includes(team.id)
                    ? 'bg-blue-50 text-blue-800 hover:bg-blue-100'
                    : 'bg-gray-50 text-gray-700 hover:bg-gray-100'
                  }
                  transition-colors
                `}
              >
                <img 
                  src={team.logo} 
                  alt={team.name} 
                  className="w-4 h-4 object-contain"
                />
                <span className="text-sm">
                  {team.name}
                  <span className="text-xs ml-1">{getTeamIndicator(team)}</span>
                </span>
                {following.includes(team.id) ? (
                  <Check size={14} className="text-blue-600" />
                ) : (
                  <Plus size={14} className="text-gray-500" />
                )}
              </button>
            );
          })}
        </div>
      ) : (
        <div className="text-center text-gray-500 py-4">
          No teams found matching "{searchTerm}"
        </div>
      )}
    </div>
  );
}

function FollowingPage() {
  const { following = [], toggleFollow } = useApp();
  const [expandedSports, setExpandedSports] = useState({});
  const [expandedLeagues, setExpandedLeagues] = useState({});
  const [searchTerm, setSearchTerm] = useState('');
  const [teamsBySport, setTeamsBySport] = useState({});
  const [loading, setLoading] = useState({});
  const [error, setError] = useState({});
  const [expandedSubCategories, setExpandedSubCategories] = useState({});
  const [isFilterSectionVisible, setIsFilterSectionVisible] = useState(false);

  const SPORTS_GROUPS = useMemo(() => getLeaguesByGroup(), []);

  useEffect(() => {
    console.log('🔍 FollowingPage Context:', {
      following,
      hasToggleFollow: !!toggleFollow,
      followingType: Array.isArray(following) ? 'array' : typeof following
    });
  }, [following, toggleFollow]);

  useEffect(() => {
    console.log('Available Sports Groups:', SPORTS_GROUPS);
    console.log('Soccer Categories:', SPORTS_GROUPS.SOCCER?.subCategories);
  }, [SPORTS_GROUPS]);

  useEffect(() => {
    console.log('🔍 Sports Groups:', SPORTS_GROUPS);
    console.log('🔍 Soccer Clubs:', SPORTS_GROUPS.Soccer?.subCategories?.CLUBS?.leagues);
    console.log('🔍 Soccer International:', SPORTS_GROUPS.Soccer?.subCategories?.INTERNATIONAL?.leagues);
  }, [SPORTS_GROUPS]);

  useEffect(() => {
    // Load teams data for sports that have followed teams
    const loadFollowedTeamsData = async () => {
      console.log('Current following list:', following);

      // Extract sport from team ID and convert to proper case
      const followedSports = new Set(
        following.map(fullTeamId => {
          // Special handling for WNBA teams - map them to Basketball
          if (fullTeamId.startsWith('wnba-')) {
            return 'Basketball';
          }
          
          // Handle soccer teams with their complex IDs
          if (fullTeamId.startsWith('soccer-')) {
            return 'Soccer';
          }
          
          const [sport] = fullTeamId.split('-');
          return sport.charAt(0).toUpperCase() + sport.slice(1).toLowerCase();
        })
      );

      console.log('Found followed sports:', [...followedSports]);
      console.log('Available sports:', Object.keys(SPORTS_GROUPS));

      // Load data for all followed sports simultaneously
      const loadPromises = Array.from(followedSports).map(sport => {
        const sportGroup = SPORTS_GROUPS[sport];
        if (!sportGroup) {
          console.warn(`Sport group not found for "${sport}". Available sports:`, Object.keys(SPORTS_GROUPS));
          return Promise.resolve();
        }

        // For Soccer, we need to load all subcategories
        if (sport === 'Soccer' && sportGroup.subCategories) {
          return Promise.all(
            Object.entries(sportGroup.subCategories).map(([categoryKey, category]) =>
              Promise.all(
                Object.entries(category.leagues).map(([leagueName, leagueConfig]) =>
                  loadTeamsForLeague(sport, categoryKey, leagueName, leagueConfig)
                )
              )
            )
          );
        }
        
        // For other sports, load normally
        return loadTeamsForSport(sport);
      });

      try {
        await Promise.all(loadPromises);
      } catch (error) {
        console.error('Error loading followed teams:', error);
      }
    };

    if (following.length > 0) {
      loadFollowedTeamsData();
    }
  }, [following, SPORTS_GROUPS]);

  // Add helper function to load teams for a specific soccer league
  const loadTeamsForLeague = async (sport, categoryKey, leagueName, leagueConfig) => {
    try {
      const endpoint = `${API_BASE_URL}/soccer/${leagueConfig.league}/teams`;
      console.log(`Fetching soccer teams for ${categoryKey} - ${leagueName} from:`, endpoint);
      
      const response = await fetch(endpoint);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      
      const data = await response.json();
      const teams = data.sports?.[0]?.leagues?.[0]?.teams?.map(team => ({
        id: `soccer-${leagueConfig.league}-${team.team.id}`,
        espnId: team.team.id,
        name: team.team.displayName,
        abbreviation: team.team.abbreviation,
        logo: team.team.logos?.[0]?.href,
        league: leagueName,
        sport: 'soccer',
        category: categoryKey,
        emoji: leagueConfig.emoji,
        color: team.team.color || '000000',
        alternateColor: team.team.alternateColor
      })) || [];

      // Update teamsBySport with the new teams
      setTeamsBySport(prev => ({
        ...prev,
        Soccer: {
          ...prev.Soccer,
          [leagueName]: teams
        }
      }));

      return teams;
    } catch (error) {
      console.error(`Error loading teams for ${leagueName}:`, error);
      return [];
    }
  };

  const loadTeamsForSport = async (sportKey) => {
    if (teamsBySport[sportKey]) return Promise.resolve();
    
    setLoading(prev => ({ ...prev, [sportKey]: true }));
    try {
      const sportGroup = SPORTS_GROUPS[sportKey];
      
      // Special handling for Soccer with subcategories
      if (sportKey === 'Soccer' && sportGroup.subCategories) {
        const allSoccerTeams = {};
        
        // Load teams for each subcategory
        for (const [categoryKey, category] of Object.entries(sportGroup.subCategories)) {
          for (const [leagueName, leagueConfig] of Object.entries(category.leagues)) {
            try {
              const endpoint = `${API_BASE_URL}/soccer/${leagueConfig.league}/teams`;
              console.log(`Fetching soccer teams for ${categoryKey} - ${leagueName} from:`, endpoint);
              
              const response = await fetch(endpoint);
              if (!response.ok) continue;
              
              const data = await response.json();
              const teams = data.sports?.[0]?.leagues?.[0]?.teams?.map(team => ({
                id: `soccer-${leagueConfig.league}-${team.team.id}`,
                espnId: team.team.id,
                name: team.team.displayName,
                abbreviation: team.team.abbreviation,
                logo: team.team.logos?.[0]?.href,
                league: leagueName,
                sport: 'soccer',
                category: categoryKey,
                emoji: leagueConfig.emoji,
                color: team.team.color || '000000',
                alternateColor: team.team.alternateColor
              })) || [];
              
              if (teams.length > 0) {
                allSoccerTeams[leagueName] = teams;
              }
            } catch (error) {
              console.error(`Error loading soccer teams for ${leagueName}:`, error);
            }
          }
        }
        
        setTeamsBySport(prev => ({
          ...prev,
          [sportKey]: allSoccerTeams
        }));
        
      } else {
        // Handle other sports (including WNBA) as before
        const leaguePromises = Object.entries(sportGroup.leagues || {}).map(async ([leagueName, { league }]) => {
          try {
            const isCollege = league.includes('college');
            const isWNBA = leagueName === 'WNBA';
            
            const sportPath = isWNBA ? 'basketball/wnba' : `${sportGroup.name.toLowerCase()}/${league}`;
            const endpoint = `${API_BASE_URL}/${sportPath}/teams${isCollege ? '?limit=500&groups=50' : ''}`;
            
            const response = await fetch(endpoint);
            if (!response.ok) {
              throw new Error(`HTTP error! status: ${response.status}`);
            }
            
            const data = await response.json();
            const teams = data.sports?.[0]?.leagues?.[0]?.teams?.map(team => ({
              id: isWNBA ? `wnba-${team.team.id}` : `${sportGroup.name.toLowerCase()}-${league}-${team.team.id}`,
              espnId: team.team.id,
              name: isCollege ? `${team.team.location} ${team.team.name}` : team.team.displayName,
              abbreviation: team.team.abbreviation,
              logo: team.team.logos?.[0]?.href,
              league: leagueName,
              sport: isWNBA ? 'wnba' : sportGroup.name.toLowerCase(),
              emoji: sportGroup.leagues[leagueName].emoji,
              color: team.team.color || '000000',
              alternateColor: team.team.alternateColor,
              isWNBA: isWNBA,
              ...(isCollege && { conference: team.team.conferenceId })
            })) || [];
            
            return { leagueName, teams };
          } catch (error) {
            console.error(`Error loading teams for ${leagueName}:`, error);
            return { leagueName, teams: [] };
          }
        });

        const leagueResults = await Promise.all(leaguePromises);
        const sportTeams = leagueResults.reduce((acc, { leagueName, teams }) => {
          if (teams.length > 0) {
            acc[leagueName] = teams;
          }
          return acc;
        }, {});

        setTeamsBySport(prev => ({
          ...prev,
          [sportKey]: sportTeams
        }));
      }
      
    } catch (err) {
      console.error(`Error loading teams for ${sportKey}:`, err);
      setError(prev => ({
        ...prev,
        [sportKey]: 'Failed to load teams'
      }));
      return Promise.reject(err);
    } finally {
      setLoading(prev => ({ ...prev, [sportKey]: false }));
    }
  };

  const toggleSport = async (sportKey) => {
    const isExpanding = !expandedSports[sportKey];
    setExpandedSports(prev => ({
      ...prev,
      [sportKey]: isExpanding
    }));
    
    if (isExpanding) {
      await loadTeamsForSport(sportKey);
    }
  };

  const handleFollowToggle = async (teamId) => {
    if (!toggleFollow) {
      console.error('toggleFollow function not available');
      return;
    }
    
    // If it's a WNBA team, remove any NBA version of the same team
    if (teamId.startsWith('wnba-')) {
      const nbaVersion = `basketball-${teamId.split('wnba-')[1]}`;
      
      // If we're following the NBA version, unfollow it first
      if (following.includes(nbaVersion)) {
        try {
          await toggleFollow(nbaVersion);
        } catch (error) {
          console.error('Error removing NBA team version:', error);
        }
      }
    }
    
    // Toggle the team
    try {
      await toggleFollow(teamId);
    } catch (error) {
      console.error('Error toggling team follow:', error);
    }
  };

  const toggleSubCategory = (sportKey, categoryKey) => {
    setExpandedSubCategories(prev => ({
      ...prev,
      [`${sportKey}-${categoryKey}`]: !prev[`${sportKey}-${categoryKey}`]
    }));
  };

  // Add this new effect to handle search-based expansions
  useEffect(() => {
    if (!searchTerm) {
      // Reset expansions when search is cleared
      setExpandedSports({});
      setExpandedLeagues({});
      setExpandedSubCategories({});
      return;
    }

    // Load and expand all sports that haven't been loaded yet
    Object.keys(SPORTS_GROUPS).forEach(async (sportKey) => {
      if (!teamsBySport[sportKey]) {
        await loadTeamsForSport(sportKey);
      }
      
      // Expand the sport if it has matching teams
      const sportTeams = teamsBySport[sportKey] || {};
      const hasMatchingTeams = Object.values(sportTeams).some(teams =>
        teams.some(team =>
          team.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          team.abbreviation?.toLowerCase().includes(searchTerm.toLowerCase())
        )
      );

      if (hasMatchingTeams) {
        setExpandedSports(prev => ({ ...prev, [sportKey]: true }));
        
        // For soccer, expand relevant subcategories
        if (sportKey === 'Soccer') {
          Object.keys(SPORTS_GROUPS.Soccer.subCategories).forEach(categoryKey => {
            setExpandedSubCategories(prev => ({
              ...prev,
              [`${sportKey}-${categoryKey}`]: true
            }));
          });
        }
      }
    });
  }, [searchTerm]);

  // Render the sports groups and their leagues
  return (
    <div className="space-y-6">
      {/* Following Teams Section */}
      <FollowingTeams 
        following={following} 
        teamsBySport={teamsBySport} 
        onFollowToggle={handleFollowToggle} 
      />

      {/* Search and Filter Section */}
      <section className="bg-white rounded-xl shadow-sm p-6">
        <div className="relative w-full">
          <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
          <input
            type="text"
            placeholder="Search teams..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="pl-10 pr-4 py-2 w-full border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 outline-none"
          />
        </div>
        <SearchResults 
          searchTerm={searchTerm}
          teamsBySport={teamsBySport}
          following={following}
          onFollowToggle={handleFollowToggle}
        />
      </section>

      {/* Sports Selection Section - Only show when not searching */}
      {!searchTerm && (
        <section className="bg-white rounded-xl shadow-sm p-6">
          <h2 className="text-lg font-semibold mb-4">Select Teams by Sport</h2>
          <div className="space-y-3">
            {Object.entries(SPORTS_GROUPS).map(([sportKey, sportGroup]) => (
              <div key={sportKey} className="border rounded-lg overflow-hidden">
                <button
                  onClick={() => toggleSport(sportKey)}
                  className="w-full px-4 py-3 bg-gray-50 hover:bg-gray-100 flex items-center justify-between"
                >
                  <div className="flex items-center gap-3">
                    <span className="text-xl">{sportGroup.emoji}</span>
                    <span className="font-medium">{sportGroup.name}</span>
                  </div>
                  {Object.values(teamsBySport[sportKey] || {})
                    .flat()
                    .filter(team => following.includes(team.id)).length > 0 && (
                    <span className="text-sm bg-blue-100 text-blue-700 px-2 py-0.5 rounded-full">
                      {Object.values(teamsBySport[sportKey] || {})
                        .flat()
                        .filter(team => following.includes(team.id)).length}
                    </span>
                  )}
                </button>

                {expandedSports[sportKey] && (
                  <div className="p-4 space-y-4">
                    {loading[sportKey] ? (
                      <div className="text-center py-4">
                        <LoadingSpinner />
                      </div>
                    ) : error[sportKey] ? (
                      <div className="text-center text-red-500 py-4">{error[sportKey]}</div>
                    ) : sportKey === 'Soccer' ? (
                      // Nested dropdowns for Soccer's subcategories
                      Object.entries(sportGroup.subCategories).map(([categoryKey, category]) => (
                        <div key={categoryKey} className="border rounded-lg overflow-hidden">
                          <button
                            onClick={() => toggleSubCategory(sportKey, categoryKey)}
                            className="w-full px-4 py-2 bg-gray-50 hover:bg-gray-100 flex items-center justify-between"
                          >
                            <span className="font-medium">{category.name}</span>
                            {/* Add following count for category */}
                            {Object.entries(teamsBySport[sportKey] || {})
                              .filter(([leagueName]) => category.leagues[leagueName])
                              .reduce((count, [_, teams]) => 
                                count + teams.filter(team => following.includes(team.id)).length, 
                              0) > 0 && (
                              <span className="text-sm bg-blue-100 text-blue-700 px-2 py-0.5 rounded-full">
                                {Object.entries(teamsBySport[sportKey] || {})
                                  .filter(([leagueName]) => category.leagues[leagueName])
                                  .reduce((count, [_, teams]) => 
                                    count + teams.filter(team => following.includes(team.id)).length, 
                                  0)}
                              </span>
                            )}
                          </button>

                          {expandedSubCategories[`${sportKey}-${categoryKey}`] && (
                            <div className="p-2 space-y-2">
                              {Object.entries(teamsBySport[sportKey] || {})
                                .filter(([leagueName]) => category.leagues[leagueName])
                                .map(([leagueName, teams]) => (
                                  <LeagueTeams
                                    key={leagueName}
                                    leagueName={leagueName}
                                    teams={teams}
                                    following={following}
                                    onFollowToggle={handleFollowToggle}
                                    searchTerm={searchTerm}
                                  />
                                ))}
                            </div>
                          )}
                        </div>
                      ))
                    ) : (
                      // Regular handling for other sports
                      Object.entries(teamsBySport[sportKey] || {}).map(([leagueName, teams]) => (
                        <LeagueTeams
                          key={leagueName}
                          leagueName={leagueName}
                          teams={teams}
                          following={following}
                          onFollowToggle={handleFollowToggle}
                          searchTerm={searchTerm}
                        />
                      ))
                    )}
                  </div>
                )}
              </div>
            ))}
          </div>
        </section>
      )}
    </div>
  );
}

// Separate component for league teams
function LeagueTeams({ leagueName, teams, following, onFollowToggle, searchTerm }) {
  const [expanded, setExpanded] = useState(false);
  const league = Object.values(LEAGUES).find(l => l.name === leagueName);
  
  const filteredTeams = teams
    .filter(team =>
      team.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      team.abbreviation?.toLowerCase().includes(searchTerm.toLowerCase())
    )
    .sort((a, b) => a.name.localeCompare(b.name));

  useEffect(() => {
    if (searchTerm && filteredTeams.length > 0) {
      setExpanded(true);
    } else if (!searchTerm) {
      setExpanded(false);
    }
  }, [searchTerm, filteredTeams.length]);

  // Move conditional return after all hooks
  if (searchTerm) return null;

  return (
    <div className="border rounded-lg overflow-hidden">
      <button
        onClick={() => setExpanded(!expanded)}
        className="w-full px-4 py-2 bg-gray-50 hover:bg-gray-100 flex items-center justify-between"
      >
        <div className="flex items-center gap-2">
          {league?.logo ? (
            <img src={league.logo} alt="" className="w-5 h-5 object-contain" />
          ) : (
            <span className="text-xl">{league?.emoji}</span>
          )}
          <span>{leagueName}</span>
          {teams.filter(team => following.includes(team.id)).length > 0 && (
            <span className="text-sm bg-blue-100 text-blue-700 px-2 py-0.5 rounded-full">
              {teams.filter(team => following.includes(team.id)).length}
            </span>
          )}
        </div>
        {expanded ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
      </button>

      {expanded && (
        <div className="p-4">
          <div className="flex flex-wrap gap-2">
            {filteredTeams.map(team => (
              <button
                key={`league-${team.id}-${leagueName}`}
                onClick={() => onFollowToggle(team.id)}
                className={`
                  inline-flex items-center gap-2 px-3 py-1.5 rounded-full
                  ${following.includes(team.id)
                    ? 'bg-blue-50 text-blue-800 hover:bg-blue-100'
                    : 'bg-gray-50 text-gray-700 hover:bg-gray-100'
                  }
                  transition-colors
                `}
              >
                <img 
                  src={team.logo} 
                  alt={team.name} 
                  className="w-4 h-4 object-contain"
                />
                <span className="text-sm">
                  {team.name}
                  <span className="text-xs ml-1">{getTeamIndicator(team)}</span>
                </span>
                {following.includes(team.id) ? (
                  <Check size={14} className="text-blue-600" />
                ) : (
                  <Plus size={14} className="text-gray-500" />
                )}
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

// Separate component for team row
function TeamRow({ team, following, onFollowToggle }) {
  const navigate = useNavigate();
  const isGolfPlayer = team.id.startsWith('golf-');
  const isFollowing = following.includes(team.id);

  return (
    <div className="flex items-center justify-between p-4 hover:bg-gray-50">
      <div className="flex items-center space-x-3">
        {team.logo ? (
          <img 
            src={team.logo} 
            alt={team.name} 
            className="w-8 h-8 object-contain cursor-pointer hover:opacity-80 transition-opacity"
            onClick={() => navigate(`/team/${team.id}`)}
          />
        ) : (
          <span className="text-2xl">{team.emoji}</span>
        )}
        <div>
          <div 
            className="font-medium cursor-pointer hover:text-blue-600"
            onClick={() => navigate(`/team/${team.id}`)}
          >
            {team.name}
            {isGolfPlayer && team.rank && (
              <span className="ml-2 text-sm text-gray-500">#{team.rank}</span>
            )}
          </div>
          {team.abbreviation && (
            <div className="text-sm text-gray-500">
              {isGolfPlayer ? team.nationality : team.abbreviation}
            </div>
          )}
          {isGolfPlayer && team.statistics && (
            <div className="text-xs text-gray-400">
              Wins: {team.statistics.wins} | Top 10s: {team.statistics.topTens}
            </div>
          )}
        </div>
      </div>
      <button
        onClick={() => onFollowToggle(team.id)}
        className={`p-2 rounded-full ${
          isFollowing
            ? 'bg-blue-100 text-blue-700 hover:bg-blue-200'
            : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
        }`}
        aria-label={isFollowing ? 'Unfollow' : 'Follow'}
      >
        {isFollowing ? (
          <Check className="w-4 h-4" />
        ) : (
          <Plus className="w-4 h-4" />
        )}
      </button>
    </div>
  );
}

export default FollowingPage;