import React from 'react';

export default function DevNotesPage() {
  return (
    <div className="max-w-2xl mx-auto py-8 px-0 sm:px-4">
      <div className="bg-white rounded-xl shadow-sm p-6">
        <h1 className="text-2xl font-bold mb-6">👋 Welcome to Recap</h1>
        
        <div className="space-y-4 text-gray-600">
          <p>
            Hi, I'm Tim! Full disclosure, I'm not a developer.
          </p>
          
          <p>
            I created Recap because I wanted a centralized place to follow 
            all my teams / leagues without having to navigate through six different apps where I would inevitably have the score spoiled for me.
          </p>

          <p>
          Follow the teams you like, see their games/scores on your home screen, queue games to watch later, and choose to hide the score if you want to avoid spoilers before watching highlights.
          </p>
          
          <p>
            This is very much a work in progress, and I'd love to hear your thoughts! Specifically:
          </p>
          
          <div className="space-y-2">
            <div className="flex items-start">
              <span className="text-blue-500 mr-2">•</span>
              <span>Which leagues would you like to see added?</span>
            </div>
            <div className="flex items-start">
              <span className="text-blue-500 mr-2">•</span>
              <span>What features aren't working as expected?</span>
            </div>
            <div className="flex items-start">
              <span className="text-blue-500 mr-2">•</span>
              <span>How could I make the experience better?</span>
            </div>
          </div>
          
          <p className="pt-2">
            If you have any feedback or suggestions, I'm all ears! You can reach me at{' '}
            <a 
              href="mailto:hey@recapsports.app" 
              className="text-blue-600 hover:text-blue-800 hover:underline"
            >
              hey@recapsports.app
            </a>
          </p>
          
          <p className="pt-2">
            Thanks for trying out Recap!
          </p>
        </div>
      </div>
    </div>
  );
} 