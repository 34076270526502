import { Navigate } from 'react-router-dom';
import { useApp } from '../contexts/AppContext';

export function ProtectedRoute({ children }) {
  const { currentUser, isGuest } = useApp();

  // Allow access if user is authenticated OR in guest mode
  if (currentUser || isGuest) {
    return children;
  }

  // Redirect to login if not authenticated and not in guest mode
  return <Navigate to="/login" replace />;
} 