import { useApp } from '../contexts/AppContext';
import { LogOut, Youtube, HelpCircle, FileText, Settings, Sliders } from 'lucide-react';
import { auth } from '../services/firebase';
import { Link, useNavigate } from 'react-router-dom';

export default function Sidebar({ isOpen, onClose }) {
  const { isGuest } = useApp();
  const navigate = useNavigate();

  const handleSignOut = async () => {
    try {
      await auth.signOut();
      onClose();
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  return (
    <>
      {/* Backdrop */}
      {isOpen && (
        <div 
          className="fixed inset-0 bg-black bg-opacity-50 z-40 transition-opacity"
          onClick={onClose}
        />
      )}

      {/* Sidebar */}
      <div 
        className={`fixed right-0 top-0 h-full w-64 bg-white shadow-lg z-50 transform transition-transform duration-300 ease-in-out ${
          isOpen ? 'translate-x-0' : 'translate-x-full'
        }`}
      >
        <div className="p-4">
          <h2 className="text-lg font-semibold mb-4">Settings</h2>
          
          <div className="space-y-4">
            <Link
              to="/preferences"
              onClick={onClose}
              className="w-full flex items-center gap-2 p-2 text-gray-700 rounded-lg hover:bg-gray-100"
            >
              <Sliders className="w-5 h-5" />
              <span>Preferences</span>
            </Link>

            {/* Channel Settings Link */}
            <Link
              to="/channels"
              onClick={onClose}
              className="w-full flex items-center gap-2 p-2 text-gray-700 rounded-lg hover:bg-gray-100"
            >
              <Youtube className="w-5 h-5" />
              <span>Channels</span>
            </Link>

            {/* Developer Notes Link */}
            <Link
              to="/dev-notes"
              onClick={onClose}
              className="w-full flex items-center gap-2 p-2 text-gray-700 rounded-lg hover:bg-gray-100"
            >
              <FileText className="w-5 h-5" />
              <span>Developer Notes</span>
            </Link>

            {/* About Link */}
            <Link
              to="/about"
              onClick={onClose}
              className="w-full flex items-center gap-2 p-2 text-gray-700 rounded-lg hover:bg-gray-100"
            >
              <HelpCircle className="w-5 h-5" />
              <span>How to Use</span>
            </Link>

            {/* Authentication Buttons */}
            {isGuest ? (
              <div className="space-y-2">
                <button
                  onClick={() => {
                    navigate('/login');
                    onClose();
                  }}
                  className="w-full py-2 px-4 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
                >
                  Sign In
                </button>
                <button
                  onClick={() => {
                    navigate('/register');
                    onClose();
                  }}
                  className="w-full py-2 px-4 border border-blue-600 text-blue-600 rounded-lg hover:bg-blue-50"
                >
                  Sign Up
                </button>
              </div>
            ) : (
              <button
                onClick={handleSignOut}
                className="w-full flex items-center gap-2 p-2 text-red-600 rounded-lg hover:bg-red-50"
              >
                <LogOut className="w-5 h-5" />
                <span>Sign Out</span>
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
} 