// src/services/youtubeApi.js

import { LEAGUE_CHANNELS } from '../config/leagues';

const CACHE_DURATION = 24 * 60 * 60 * 1000; // 24 hours

// Sport-specific formatting for better search results
const SPORT_FORMATS = {
  soccer: (homeTeam, awayTeam) => `${homeTeam} vs ${awayTeam}`,
  hockey: (homeTeam, awayTeam) => `${homeTeam} vs ${awayTeam}`,
  basketball: (homeTeam, awayTeam) => `${homeTeam} vs ${awayTeam}`,
  football: (homeTeam, awayTeam) => `${homeTeam} vs ${awayTeam}`,
  baseball: (homeTeam, awayTeam) => `${homeTeam} vs ${awayTeam}`
};

const SPORT_BY_LEAGUE = {
  'NBA': 'basketball',
  'NFL': 'football',
  'MLB': 'baseball',
  'Premier League': 'soccer',
  'La Liga': 'soccer',
  'Serie A': 'soccer',
  'NHL': 'hockey',
  'Champions League': 'soccer',
  'Europa League': 'soccer',
  'Bundesliga': 'soccer',
  'Men\'s College Basketball': 'basketball',
  'Women\'s College Basketball': 'basketball',
  'College Football': 'football',
  'Turkish Super Lig': 'soccer',
  'Scottish Premiership': 'soccer',
  'English Championship': 'soccer'
};

const highlightCache = new Map();

const HIGHLIGHT_DELAY_HOURS = 3; // Wait at least 3 hours after game end

const GAME_DELAYS = {
  // Soccer leagues
  'Premier League': 3,
  'La Liga': 3,
  'Serie A': 3,
  'Bundesliga': 3,
  'Champions League': 3,
  'Europa League': 3,
  'MLS': 3,
  'Turkish Super Lig': 3,
  'Scottish Premiership': 3,
  'Ligue 1': 3,
  'Eredivisie': 3,
  'English Championship': 3,
  
  // Other sports
  'NFL': 4.5,  // 4.5 hours after kickoff
  'NBA': 3.5,  // 3.5 hours after tipoff
  'MLB': 3.5,  // 3.5 hours after first pitch
  'NHL': 3.5,  // 3.5 hours after puck drop
  
  // College sports
  'Men\'s College Basketball': 3.5,
  'Women\'s College Basketball': 3.5,
  'College Football': 4.5,
  
  // Default delay if not specified
  'DEFAULT': 3
};

export const youtubeApi = {
  isHighlightAvailable(game) {
    // Check if league is supported
    if (!LEAGUE_CHANNELS[game.league]) {
      return false;
    }

    const now = new Date();
    const gameDate = new Date(game.rawDate);
    
    // Get the delay for this league, or use default
    const delayHours = GAME_DELAYS[game.league] || GAME_DELAYS.DEFAULT;
    
    // Calculate hours since game started
    const hoursSinceStart = (now - gameDate) / (1000 * 60 * 60);
    
    // For completed games, check if enough time has passed
    if (game.status === 'Final') {
      return hoursSinceStart >= delayHours;
    }
    
    // For games not marked as Final, be more conservative
    return hoursSinceStart >= (delayHours + 1);
  },

  async getHighlightVideo(homeTeam, awayTeam, rawDate, league) {
    try {
      // Try to get specific video first
      const result = await this.searchWithBackoff(
        `${homeTeam} vs ${awayTeam} ${LEAGUE_CHANNELS[league]?.searchParam || 'highlights'}`,
        { homeTeam, awayTeam, rawDate, league }
      );

      if (result.type === 'video') {
        return {
          type: 'direct',
          videoId: result.videoId
        };
      }

      return {
        type: 'search',
        searchQuery: `${homeTeam} vs ${awayTeam} highlights`
      };
    } catch (error) {
      console.error('Error fetching highlights:', error);
      return {
        type: 'search',
        searchQuery: `${homeTeam} vs ${awayTeam} highlights`
      };
    }
  },

  async searchWithBackoff(query, gameInfo, attempt = 1) {
    const MAX_ATTEMPTS = 3;
    const BACKOFF_MS = 1000 * Math.pow(2, attempt - 1);

    try {
      await new Promise(resolve => setTimeout(resolve, Math.random() * 1000));
      return await this.makeSearchRequest(query, gameInfo);
    } catch (error) {
      if (error.status === 429 && attempt < MAX_ATTEMPTS) {
        await new Promise(resolve => setTimeout(resolve, BACKOFF_MS));
        return this.searchWithBackoff(query, gameInfo, attempt + 1);
      }
      throw error;
    }
  },

  async makeSearchRequest(query, gameInfo) {
    const apiKey = 'AIzaSyC3IT5jWXhMbHEuyKe_66bSJH2m5DuQTy8';
    const encodedQuery = encodeURIComponent(query);
    
    try {
      const response = await fetch(`https://www.googleapis.com/youtube/v3/search?part=snippet&maxResults=10&q=${encodedQuery}&key=${apiKey}`);
      const data = await response.json();
      
      if (!data.items?.length) {
        return {
          type: 'error',
          message: 'No highlights found. Please try again later.',
          searchQuery: `${gameInfo.homeTeam} vs ${gameInfo.awayTeam} highlights`
        };
      }

      // Score each video based on multiple factors
      const scoredVideos = data.items.map(video => {
        let score = 0;
        const title = video.snippet.title.toLowerCase();
        const channelTitle = video.snippet.channelTitle.toLowerCase();
        const publishDate = new Date(video.snippet.publishedAt);
        
        // Check channels in priority order
        const customSettings = JSON.parse(localStorage.getItem('channelSettings'));
        const activeChannels = customSettings || LEAGUE_CHANNELS;
        const leagueChannels = activeChannels[gameInfo.league]?.channels || [];
        const channelIndex = leagueChannels.findIndex(channel => 
          channelTitle.includes(channel.toLowerCase())
        );

        // If not found in our channels list, return minimal score
        if (channelIndex === -1) {
          return { score: 0, id: video.id, title, channel: channelTitle };
        }

        // Add score based on channel priority (higher for earlier in array)
        score += Math.max(0, 100 - (channelIndex * 10)); // First channel gets 100, second 90, etc.

        // Title relevance
        if (title.includes('highlights')) score += 20;
        if (title.includes('extended highlights')) score += 25;
        if (title.includes(gameInfo.homeTeam.toLowerCase()) && 
            title.includes(gameInfo.awayTeam.toLowerCase())) score += 30;
        
        // Date relevance
        const gameDate = new Date(gameInfo.rawDate);
        if (gameDate) {
          const daysDiff = Math.abs(publishDate - gameDate) / (1000 * 60 * 60 * 24);
          if (daysDiff <= 3) score += Math.max(0, 30 - (daysDiff * 10));
        }

        return { 
          id: video.id, 
          score, 
          title, 
          channel: channelTitle,
          channelPriority: channelIndex
        };
      });

      // Sort first by channel priority, then by other scores
      const bestMatch = scoredVideos
        .sort((a, b) => {
          // If they're from different channels, prioritize the higher priority channel
          if (a.channelPriority !== b.channelPriority) {
            return a.channelPriority - b.channelPriority;
          }
          // If they're from the same channel, use the regular scoring
          return b.score - a.score;
        })[0];
      
      // If no videos from trusted channels met our criteria
      if (bestMatch.score < 50) {
        return {
          type: 'error',
          message: `No highlights found yet on official channels. You can check back later or perform a manual search (warning: search results may contain spoilers).`,
          searchQuery: `${gameInfo.homeTeam} vs ${gameInfo.awayTeam} highlights`
        };
      }

      return {
        type: 'video',
        videoId: bestMatch.id.videoId
      };
      
    } catch (error) {
      return {
        type: 'error',
        message: 'Error fetching highlights. Please try again later.',
        searchQuery: `${gameInfo.homeTeam} vs ${gameInfo.awayTeam} highlights`
      };
    }
  },

  openVideoInModal(videoId) {
    const modal = document.createElement('div');
    modal.innerHTML = `
      <div class="modal">
        <iframe width="560" height="315" src="https://www.youtube.com/embed/${videoId}" frameborder="0" allowfullscreen></iframe>
      </div>
    `;
    document.body.appendChild(modal);
  },

  formatGameDate(date) {
    return date.toLocaleDateString('en-US', { 
      year: 'numeric',
      month: 'long', 
      day: 'numeric'
    });
  },

  // Fallback method to handle bot detection/embedding issues
  handleVideoError(videoId, homeTeam, awayTeam) {
    // Return a direct link to YouTube search
    const searchQuery = `${homeTeam} vs ${awayTeam} highlights`;
    const searchUrl = `https://www.youtube.com/results?search_query=${encodeURIComponent(searchQuery)}`;
    
    // Open in new tab instead of embedded player
    window.open(searchUrl, '_blank');
    return true;
  },

  showSpoilerCoverDialog() {
    return new Promise((resolve) => {
      const dialog = document.createElement('div');
      dialog.className = 'fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4';
      
      const content = `
        <div class="bg-white rounded-lg p-6 max-w-md">
          <h3 class="text-lg font-semibold mb-4">Opening Highlights</h3>
          <p class="text-gray-600 mb-6">
            We'll open this directly in YouTube to protect you from spoilers in the title and thumbnail! 🙈
          </p>
          <div class="flex justify-end gap-4">
            <button 
              class="cancel-btn px-4 py-2 text-gray-600 hover:bg-gray-100 rounded"
            >
              Cancel
            </button>
            <button 
              class="continue-btn px-4 py-2 bg-blue-600 text-white hover:bg-blue-700 rounded"
            >
              Continue to YouTube
            </button>
          </div>
        </div>
      `;
      
      dialog.innerHTML = content;
      
      const handleClose = (confirmed) => {
        dialog.remove();
        resolve(confirmed);
      };

      dialog.querySelector('.cancel-btn').addEventListener('click', () => handleClose(false));
      dialog.querySelector('.continue-btn').addEventListener('click', () => handleClose(true));

      document.body.appendChild(dialog);
    });
  },
};