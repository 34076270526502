// Add API_BASE_URL at the top of the file
const API_BASE_URL = 'https://site.api.espn.com/apis/site/v2/sports';

// First, define the LEAGUES constant
const LEAGUES = {
  // Basketball
  NBA: { 
    id: 'nba', 
    name: 'NBA',
    shortName: 'NBA',
    sport: 'basketball',
    emoji: '🏀',
    logo: 'https://a.espncdn.com/combiner/i?img=/i/teamlogos/leagues/500/nba.png',
    channels: ['House of Highlights', 'NBA', 'ESPN'],
    searchParam: 'NBA Highlights',
    officialChannel: 'UCWJ2lWNubArHWmf3FIHbfcQ',
    highlightDelay: 3,
    espnCategory: 'basketball',
    espnLeague: 'nba',
    displayPriority: 1
  },
  WNBA: { 
    id: 'wnba', 
    name: 'WNBA',
    shortName: 'WNBA',
    sport: 'basketball',
    emoji: '🏀',
    logo: 'https://a.espncdn.com/i/teamlogos/leagues/500/wnba.png',
    channels: ['WNBA', 'ESPN'],
    searchParam: 'WNBA Highlights',
    highlightDelay: 3,
    espnCategory: 'basketball',
    espnLeague: 'wnba',
    displayPriority: 2
  },
  NCAAB_MENS: { 
    id: 'mens-college-basketball', 
    name: "Men's College Basketball",
    shortName: "NCAAM",
    sport: 'basketball',
    emoji: '🏀',
    logo: '/images/NCAA_logo.svg',
    channels: ['NCAA March Madness', 'ESPN'],
    searchParam: 'NCAA Basketball Highlights',
    highlightDelay: 3,
    espnCategory: 'basketball',
    espnLeague: 'mens-college-basketball',
    isCollege: true,
    displayPriority: 3
  },
  NCAAB_WOMENS: { 
    id: 'womens-college-basketball', 
    name: "Women's College Basketball",
    shortName: "NCAAW",
    sport: 'basketball',
    emoji: '🏀',
    logo: '/images/NCAA_logo.svg',
    channels: ['NCAA March Madness', 'ESPN'],
    searchParam: 'NCAA Women Basketball Highlights',
    highlightDelay: 3,
    espnCategory: 'basketball',
    espnLeague: 'womens-college-basketball',
    isCollege: true,
    displayPriority: 4
  },

  // Football
  NFL: { 
    id: 'nfl', 
    name: 'NFL',
    shortName: 'NFL',
    sport: 'football',
    emoji: '🏈',
    logo: 'https://a.espncdn.com/combiner/i?img=/i/teamlogos/leagues/500/nfl.png',
    channels: ['NFL', 'NFL Network', 'ESPN'],
    searchParam: 'NFL Highlights',
    officialChannel: 'UCDVYQ4Zhbm3S2dlz7P1GBDg',
    highlightDelay: 3,
    espnCategory: 'football',
    espnLeague: 'nfl',
    displayPriority: 1
  },
  NCAAF: { 
    id: 'college-football', 
    name: 'College Football',
    shortName: 'CFB',
    sport: 'football',
    emoji: '🏈',
    channels: ['ESPN College Football', 'ESPN'],
    searchParam: 'NCAA Football Highlights',
    highlightDelay: 3,
    espnCategory: 'football',
    espnLeague: 'college-football',
    isCollege: true,
    displayPriority: 2
  },

  // Baseball
  MLB: { 
    id: 'mlb', 
    name: 'MLB',
    shortName: 'MLB',
    sport: 'baseball',
    emoji: '⚾',
    logo: 'https://a.espncdn.com/i/teamlogos/leagues/500/mlb.png',
    channels: ['MLB', 'ESPN'],
    searchParam: 'MLB Highlights',
    highlightDelay: 3,
    espnCategory: 'baseball',
    espnLeague: 'mlb',
    displayPriority: 1
  },

  // Hockey
  NHL: { 
    id: 'nhl', 
    name: 'NHL',
    shortName: 'NHL',
    sport: 'hockey',
    emoji: '🏒',
    logo: 'https://a.espncdn.com/i/teamlogos/leagues/500/nhl.png',
    channels: ['NHL', 'SPORTSNET'],
    searchParam: 'NHL Highlights',
    officialChannel: 'UCqFMzb-4AUf6WAIbl132QKA',
    highlightDelay: 3,
    espnCategory: 'hockey',
    espnLeague: 'nhl',
    displayPriority: 1
  },

  // Soccer - International
  FIFA_MENS: { 
    id: 'fifa.world', 
    name: 'FIFA Men\'s',
    shortName: 'FIFAM',
    sport: 'soccer',
    category: 'international',
    emoji: '🏆',
    logo: '/images/fifa.svg',
    channels: ['FIFA', 'FOX Soccer'],
    searchParam: 'FIFA World Cup Highlights',
    highlightDelay: 3,
    espnCategory: 'soccer',
    espnLeague: 'fifa.world',
    isInternational: true,
    displayPriority: 1
  },
  FIFA_WOMENS: { 
    id: 'fifa.wwc',
    name: 'FIFA Women\'s',
    shortName: 'FIFAW',
    sport: 'soccer',
    category: 'international',
    emoji: '🏆',
    logo: '/images/fifa.svg',
    channels: ['FIFA', 'FOX Soccer'],
    searchParam: 'FIFA Women World Cup Highlights',
    highlightDelay: 3,
    espnCategory: 'soccer',
    espnLeague: 'fifa.wwc',
    isInternational: true,
    displayPriority: 2
  },
  CHAMPIONS_LEAGUE: { 
    id: 'uefa.champions', 
    name: 'UEFA Champions League',
    shortName: 'UCL',
    sport: 'soccer',
    category: 'clubs',
    emoji: '🏆',
    logo: 'https://a.espncdn.com/combiner/i?img=/i/leaguelogos/soccer/500/2.png',
    channels: ['CBS Sports Golazo', 'UEFA Champions League'],
    searchParam: 'Champions League Highlights CBS Sports',
    highlightDelay: 3,
    espnCategory: 'soccer',
    espnLeague: 'uefa.champions',
    displayPriority: 1
  },

  // Soccer - Club Competitions
  PREMIER_LEAGUE: { 
    id: 'eng.1', 
    name: 'Premier League',
    shortName: 'EPL',
    sport: 'soccer',
    category: 'club',
    emoji: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
    logo: 'https://a.espncdn.com/combiner/i?img=/i/leaguelogos/soccer/500/23.png',
    channels: ['NBC Sports', 'Peacock', 'Premier League', 'Sky Sports Football'],
    searchParam: 'Premier League Highlights NBC',
    officialChannel: 'UCqZQlzSHbVJrwrn5XvzrzcA',
    highlightDelay: 3,
    espnCategory: 'soccer',
    espnLeague: 'eng.1',
    displayPriority: 2,
    country: 'England'
  },
  WOMENS_SUPER_LEAGUE: {
    id: 'eng.w.1',
    name: 'Women\'s Super League',
    shortName: 'WSL',
    sport: 'soccer',
    category: 'club',
    emoji: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
    logo: '/images/WSL.png',
    channels: ['Sky Sports WSL', 'BBC Sport'],
    searchParam: 'WSL Highlights',
    highlightDelay: 3,
    espnCategory: 'soccer',
    espnLeague: 'eng.w.1',
    displayPriority: 2.5,
    country: 'England'
  },
  LA_LIGA: { 
    id: 'esp.1', 
    name: 'La Liga',
    shortName: 'Liga',
    sport: 'soccer',
    category: 'club',
    emoji: '🇪🇸',
    logo: 'https://a.espncdn.com/combiner/i?img=/i/leaguelogos/soccer/500/15.png',
    channels: ['ESPN FC', 'beIN SPORTS'],
    searchParam: 'La Liga Highlights',
    officialChannel: 'UCTv-XvfzLX3i4IGWAm4sbmA',
    highlightDelay: 3,
    espnCategory: 'soccer',
    espnLeague: 'esp.1',
    displayPriority: 3,
    country: 'Spain'
  },
  SERIE_A: {
    id: 'ita.1',
    name: 'Serie A',
    shortName: 'SA',
    sport: 'soccer',
    category: 'club',
    emoji: '🇮🇹',
    logo: 'https://a.espncdn.com/combiner/i?img=/i/leaguelogos/soccer/500/12.png',
    channels: ['CBS Sports Golazo'],
    searchParam: 'Serie A Highlights',
    highlightDelay: 3,
    espnCategory: 'soccer',
    espnLeague: 'ita.1',
    displayPriority: 4,
    country: 'Italy'
  },
  BUNDESLIGA: {
    id: 'ger.1',
    name: 'Bundesliga',
    shortName: 'BL',
    sport: 'soccer',
    category: 'club',
    emoji: '🇩🇪',
    logo: 'https://a.espncdn.com/combiner/i?img=/i/leaguelogos/soccer/500/10.png',
    channels: ['ESPN FC'],
    searchParam: 'Bundesliga Highlights',
    highlightDelay: 3,
    espnCategory: 'soccer',
    espnLeague: 'ger.1',
    displayPriority: 5,
    country: 'Germany'
  },
  LIGUE_1: {
    id: 'fra.1',
    name: 'Ligue 1',
    shortName: 'L1',
    sport: 'soccer',
    category: 'club',
    emoji: '🇫🇷',
    logo: 'https://a.espncdn.com/combiner/i?img=/i/leaguelogos/soccer/500/9.png',
    channels: ['beIN SPORTS'],
    searchParam: 'Ligue 1 Highlights',
    highlightDelay: 3,
    espnCategory: 'soccer',
    espnLeague: 'fra.1',
    displayPriority: 6,
    country: 'France'
  },
  EREDIVISIE: {
    id: 'ned.1',
    name: 'Eredivisie',
    shortName: 'ERE',
    sport: 'soccer',
    category: 'club',
    emoji: '🇳🇱',
    logo: '/images/eredivisie.svg',
    channels: ['ESPN'],
    searchParam: 'Eredivisie Highlights',
    highlightDelay: 3,
    espnCategory: 'soccer',
    espnLeague: 'ned.1',
    displayPriority: 7,
    country: 'Netherlands'
  },
  MLS: {
    id: 'usa.1',
    name: 'MLS',
    shortName: 'MLS',
    sport: 'soccer',
    category: 'club',
    emoji: '⚽',
    logo: 'https://a.espncdn.com/i/teamlogos/leagues/500/mls.png',
    channels: ['MLS', 'Apple TV'],
    searchParam: 'MLS Highlights',
    highlightDelay: 3,
    espnCategory: 'soccer',
    espnLeague: 'usa.1',
    displayPriority: 8,
    country: 'United States'
  },
  SUPER_LIG: {
    id: 'tur.1',
    name: 'Turkish Super Lig',
    shortName: 'TSL',
    sport: 'soccer',
    category: 'club',
    emoji: '🇹🇷',
    channels: ['beIN SPORTS'],
    searchParam: 'Super Lig Highlights',
    highlightDelay: 3,
    espnCategory: 'soccer',
    espnLeague: 'tur.1',
    displayPriority: 9,
    country: 'Turkey'
  },
  SCOTTISH_PREMIERSHIP: {
    id: 'sco.1',
    name: 'Scottish Premiership',
    shortName: 'SPL',
    sport: 'soccer',
    category: 'club',
    emoji: '🏴󠁧󠁢󠁳󠁣󠁴󠁿',
    logo: '/images/scot.svg',
    channels: ['Sky Sports Football'],
    searchParam: 'Scottish Premiership Highlights',
    highlightDelay: 3,
    espnCategory: 'soccer',
    espnLeague: 'sco.1',
    displayPriority: 10,
    country: 'Scotland'
  },
  ENGLISH_CHAMPIONSHIP: {
    id: 'eng.2',
    name: 'English Championship',
    shortName: 'EFL',
    sport: 'soccer',
    category: 'club',
    emoji: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
    channels: ['Sky Sports Football'],
    searchParam: 'Championship Highlights',
    highlightDelay: 3,
    espnCategory: 'soccer',
    espnLeague: 'eng.2',
    displayPriority: 11,
    country: 'England'
  }
};

// Helper function to get league channels configuration
const getLeagueChannels = () => {
  const channels = {};
  
  Object.values(LEAGUES).forEach(league => {
    channels[league.name] = {
      channels: league.channels || [],
      searchParam: league.searchParam || '',
      officialChannel: league.officialChannel || '',
      highlightDelay: league.highlightDelay || 3
    };
  });
  
  return channels;
};

// Now we can create LEAGUE_CHANNELS after the function is defined
const LEAGUE_CHANNELS = getLeagueChannels();

// Helper function to get leagues grouped by sport
const getLeaguesByGroup = () => {
  const groups = {};
  
  Object.values(LEAGUES).forEach(league => {
    const sport = league.sport.charAt(0).toUpperCase() + league.sport.slice(1);
    if (!groups[sport]) {
      groups[sport] = {
        name: sport,
        emoji: league.emoji,
        leagues: {}
      };
    }

    // For soccer, handle subcategories
    if (sport === 'Soccer') {
      // Determine category - default to 'CLUB' if not specified
      const categoryKey = league.category?.toUpperCase() || 'CLUB';
      
      // Initialize subcategories if needed
      if (!groups[sport].subCategories) {
        groups[sport].subCategories = {
          'CLUB': {
            name: 'Club',
            leagues: {}
          },
          'INTERNATIONAL': {
            name: 'International',
            leagues: {}
          }
        };
      }

      // Add league to appropriate category
      if (groups[sport].subCategories[categoryKey]) {
        groups[sport].subCategories[categoryKey].leagues[league.name] = {
          league: league.id,
          emoji: league.emoji || '⚾',
          logo: league.logo
        };
      }
    } else {
      // For non-soccer leagues
      groups[sport].leagues[league.name] = {
        league: league.id,
        emoji: league.emoji,
        logo: league.logo
      };
    }
  });

  return groups;
};

// Helper function to get ESPN API configuration for a league
const getESPNConfig = (leagueId) => {
  const league = Object.values(LEAGUES).find(l => l.id === leagueId);
  if (!league) return null;

  return {
    category: league.espnCategory,
    league: league.espnLeague,
    isCollege: league.isCollege || false,
    isInternational: league.isInternational || false
  };
};

// Helper function to get display name for a team based on league
const getTeamDisplayName = (team, leagueId) => {
  const league = Object.values(LEAGUES).find(l => l.id === leagueId);
  if (!league) return team.displayName;

  if (league.isCollege) {
    return `${team.location} ${team.name}`;
  }
  return team.displayName;
};

// Helper function to get ESPN API URL for a league
const getLeagueApiUrl = (leagueId, endpoint = '') => {
  const league = Object.values(LEAGUES).find(l => l.id === leagueId);
  if (!league) return null;
  return `${API_BASE_URL}/${league.sport}/${league.id}${endpoint}`;
};

// Helper function to process game status consistently
const processGameStatus = (status) => {
  const statusMap = {
    'STATUS_SCHEDULED': 'Upcoming',
    'STATUS_IN_PROGRESS': 'In Progress',
    'STATUS_FINAL': 'Final',
    'STATUS_FULL_TIME': 'Final',
    // Add more mappings as needed
  };
  return statusMap[status.type.name] || status.type.name;
};

// Helper function to format team data consistently
const formatTeamData = (team, league) => ({
  id: `${league.sport}-${team.team.id}`,
  name: team.team.displayName,
  logo: team.team.logos?.[0]?.href || 
        team.team.logo || 
        team.team?.links?.find(link => link.rel.includes('logo'))?.href || null,
  score: team.score
});

export const initializeLeagueLogos = () => {
    // function implementation
}

export {
  LEAGUES,
  LEAGUE_CHANNELS,
  getLeaguesByGroup,
  getLeagueChannels,
  getESPNConfig,
  getTeamDisplayName,
  getLeagueApiUrl,
  processGameStatus,
  formatTeamData,
  API_BASE_URL
}; 