import React, { useState } from 'react';
import { useApp } from '../contexts/AppContext';
import { LEAGUES, getLeaguesByGroup } from '../config/leagues';
import { X, Plus, ChevronUp, ChevronDown, Eye, EyeOff, RotateCcw } from 'lucide-react';

const SPORTS_GROUPS = getLeaguesByGroup();

export default function PreferencesPage() {
  const { defaultFilters, setDefaultFilters, spoilerMode, toggleSpoilerMode } = useApp();
  const [isFilterSectionVisible, setIsFilterSectionVisible] = useState(false);

  const dateFilters = [
    { id: 'recent', label: 'Recent', emoji: '🔙' },
    { id: 'upcoming', label: 'Upcoming', emoji: '🔜' }
  ];

  const toggleDateFilter = (dateId) => {
    setDefaultFilters(prev => ({
      ...prev,
      date: prev.date.includes(dateId) ? prev.date : [dateId]
    }));
  };

  const removeDateFilter = (dateId) => {
    setDefaultFilters(prev => ({
      ...prev,
      date: prev.date.filter(id => id !== dateId)
    }));
  };

  const toggleLeagueFilter = (leagueId) => {
    setDefaultFilters(prev => ({
      ...prev,
      leagues: prev.leagues.includes(leagueId)
        ? prev.leagues.filter(id => id !== leagueId)
        : [...prev.leagues, leagueId]
    }));
  };

  const removeLeagueFilter = (leagueId) => {
    setDefaultFilters(prev => ({
      ...prev,
      leagues: prev.leagues.filter(id => id !== leagueId)
    }));
  };

  const getMenuItems = () => {
    let groupedLeagues = {
      'My Teams': [{
        id: 'my-teams',
        name: 'My Teams',
        emoji: '⭐',
        displayPriority: -1
      }]
    };

    Object.values(LEAGUES).forEach(league => {
      const sport = league.sport.charAt(0).toUpperCase() + league.sport.slice(1);
      
      if (!groupedLeagues[sport]) {
        groupedLeagues[sport] = [];
      }

      if (league.sport === 'soccer') {
        const categoryName = league.category === 'international' ? 'International' : 'Club';
        if (!groupedLeagues[categoryName]) {
          groupedLeagues[categoryName] = [];
        }
        groupedLeagues[categoryName].push(league);
      } else {
        groupedLeagues[sport].push(league);
      }
    });

    Object.keys(groupedLeagues).forEach(sport => {
      if (sport !== 'My Teams') {
        groupedLeagues[sport].sort((a, b) => (a.displayPriority || 999) - (b.displayPriority || 999));
      }
    });

    return groupedLeagues;
  };

  return (
    <div className="max-w-2xl mx-auto p-4">
      <h1 className="text-2xl font-bold mb-6">Preferences</h1>
      
      <div className="space-y-6">
        {/* Spoiler Mode Section */}
        <div>
          <h2 className="text-lg font-semibold mb-4">Spoiler Mode</h2>
          <div 
            data-spoiler-mode-section
            className="border border-gray-200 rounded-lg shadow-sm overflow-hidden"
          >
            <div className="p-4 bg-white">
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-3">
                  {spoilerMode ? <EyeOff className="w-5 h-5" /> : <Eye className="w-5 h-5" />}
                  <div>
                    <p className="text-sm text-gray-500">Toggle to show / hide scores.</p>
                  </div>
                </div>
                <button
                  onClick={toggleSpoilerMode}
                  className={`
                    relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent 
                    transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2
                    ${spoilerMode ? 'bg-blue-600' : 'bg-gray-200'}
                  `}
                >
                  <span
                    className={`
                      pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 
                      transition duration-200 ease-in-out
                      ${spoilerMode ? 'translate-x-5' : 'translate-x-0'}
                    `}
                  />
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* Default Filters Section */}
        <div>
          <h2 className="text-lg font-semibold mb-4">Default Filters</h2>
          
          {/* Selected Filters Display */}
          <div className="flex flex-wrap items-center gap-2 mb-4">
            {/* Date Filters */}
            {defaultFilters.date.map(dateId => (
              <span key={dateId} className="inline-flex items-center gap-1 px-3 py-1 rounded-full text-sm bg-green-100 text-green-800">
                <span>{dateFilters.find(f => f.id === dateId)?.emoji}</span>
                {dateFilters.find(f => f.id === dateId)?.label}
                <button
                  onClick={() => removeDateFilter(dateId)}
                  className="ml-1 hover:text-green-900"
                >
                  <X size={14} />
                </button>
              </span>
            ))}

            {/* Visual separator between date and league filters */}
            {defaultFilters.date.length > 0 && defaultFilters.leagues.length > 0 && (
              <span className="h-6 w-px bg-gray-200 mx-1"></span>
            )}

            {/* League Filters */}
            {defaultFilters.leagues.map(leagueId => {
              if (leagueId === 'my-teams') {
                return (
                  <span key={leagueId} className="inline-flex items-center gap-1 px-3 py-1 rounded-full text-sm bg-blue-100 text-blue-800">
                    <span>⭐</span>
                    My Teams
                    <button
                      onClick={() => removeLeagueFilter(leagueId)}
                      className="ml-1 hover:text-blue-900"
                    >
                      <X size={14} />
                    </button>
                  </span>
                );
              }

              const league = Object.values(LEAGUES).find(l => l.id === leagueId);
              return (
                <span key={leagueId} className="inline-flex items-center gap-1 px-3 py-1 rounded-full text-sm bg-blue-100 text-blue-800">
                  {league?.logo ? (
                    <img src={league.logo} alt="" className="w-4 h-4 object-contain" />
                  ) : (
                    <span>{league?.emoji}</span>
                  )}
                  {league?.shortName || league?.name}
                  <button
                    onClick={() => removeLeagueFilter(leagueId)}
                    className="ml-1 hover:text-blue-900"
                  >
                    <X size={14} />
                  </button>
                </span>
              );
            })}

            {/* Filter controls */}
            <div className="flex items-center gap-2">
              <button
                onClick={() => setIsFilterSectionVisible(prev => !prev)}
                className="inline-flex items-center justify-center w-7 h-7 rounded-md text-gray-500 hover:bg-gray-100"
              >
                {isFilterSectionVisible ? (
                  <ChevronUp 
                    size={20}
                    className="transform transition-transform duration-200"
                  />
                ) : (
                  <Plus size={20} />
                )}
              </button>

              {(defaultFilters.leagues.length > 0 || defaultFilters.date.length > 0) && (
                <button
                  onClick={() => setDefaultFilters({ 
                    date: ['recent'],
                    leagues: [] 
                  })}
                  className="inline-flex items-center justify-center w-7 h-7 rounded-md text-gray-500 hover:bg-gray-100 translate-y-[0.5px]"
                  title="Reset filters"
                >
                  <RotateCcw size={18} />
                </button>
              )}
            </div>
          </div>

          {/* Filter Tag Bank */}
          {isFilterSectionVisible && (
            <div className="space-y-0.5 bg-gray-100 rounded-lg mt-2 border border-gray-200 shadow-sm">
              {/* Date Filters Section with distinct styling */}
              <div className="p-4 bg-white rounded-t-lg">
                <div className="flex flex-wrap gap-2">
                  {dateFilters.map(filter => {
                    const isSelected = defaultFilters.date.includes(filter.id);
                    console.log(`Filter ${filter.id} selected:`, isSelected); // Debug log
                    
                    return (
                      <button
                        key={filter.id}
                        onClick={() => toggleDateFilter(filter.id)}
                        className={`
                          px-3 py-1 rounded-full text-sm inline-flex items-center gap-1
                          transition-colors duration-150
                          ${isSelected
                            ? 'bg-green-100 text-green-800 border border-green-200'
                            : 'bg-gray-50 text-gray-600 hover:bg-green-50 border border-gray-200'
                          }
                        `}
                      >
                        <span>{filter.emoji}</span>
                        {filter.label}
                      </button>
                    );
                  })}
                </div>
              </div>

              {/* League Filters Section */}
              <div className="p-4 bg-white rounded-b-lg">
                {Object.entries(getMenuItems()).map(([sportName, leagues]) => (
                  <div key={sportName} className="mt-4 first:mt-0">
                    <div className="flex justify-between items-center mb-2">
                      <h3 className="text-xs font-medium text-gray-500">{sportName}</h3>
                      {leagues.length > 1 && (
                        <button
                          onClick={() => {
                            const leagueIds = leagues.map(league => league.id);
                            const allSelected = leagueIds.every(id => 
                              defaultFilters.leagues.includes(id)
                            );
                            
                            setDefaultFilters(prev => ({
                              ...prev,
                              leagues: allSelected
                                ? prev.leagues.filter(id => !leagueIds.includes(id))
                                : [...new Set([...prev.leagues, ...leagueIds])]
                            }));
                          }}
                          className="text-xs text-blue-600 hover:text-blue-800"
                        >
                          {leagues.every(league => 
                            defaultFilters.leagues.includes(league.id)
                          ) ? 'Deselect All' : 'Select All'}
                        </button>
                      )}
                    </div>
                    <div className="flex flex-wrap gap-2">
                      {leagues.map(league => (
                        <button
                          key={league.id}
                          onClick={() => toggleLeagueFilter(league.id)}
                          className={`
                            inline-flex items-center gap-1 px-3 py-1 rounded-full text-sm
                            ${defaultFilters.leagues.includes(league.id)
                              ? 'bg-blue-100 text-blue-800'
                              : 'bg-gray-50 text-gray-600 hover:bg-gray-100'
                            }
                          `}
                        >
                          {league.logo ? (
                            <img src={league.logo} alt="" className="w-4 h-4 object-contain" />
                          ) : (
                            <span>{league.emoji}</span>
                          )}
                          {league.shortName || league.name}
                        </button>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}

          <div className="mt-4 text-sm text-gray-500">
            <p>These will be used as your default filters when viewing games.  You can always change them temporarily on the home page.</p>
          </div>
        </div>
      </div>
    </div>
  );
} 