import React from 'react';
import { useApp } from '../contexts/AppContext';
import GamesDisplay from '../components/GamesDisplay';
import LoadingSpinner from '../components/LoadingSpinner';

function QueuePage() {
  const { queue, following, games } = useApp();

  console.log('Queue IDs:', queue);
  console.log('All Games:', games);
  console.log('Following:', following);
  
  const queuedGames = Array.isArray(games) 
    ? games
        .filter(game => queue.includes(game.id))
        .sort((a, b) => {
          const dateA = new Date(a.rawDate || a.date);
          const dateB = new Date(b.rawDate || b.date);
          return dateB - dateA;
        })
    : [];
    
  console.log('Filtered Queued Games:', queuedGames);
    
  return (
    <div className="container mx-auto px-4 py-4">
      {!Array.isArray(games) ? (
        <div className="text-center py-8">
          <LoadingSpinner />
        </div>
      ) : queuedGames.length === 0 ? (
        <div className="text-center py-8">
          <p className="text-gray-500 mt-4">No games in your queue yet.</p>
          <p className="text-sm text-gray-400 mt-2">
            Add games to your queue by clicking the bookmark icon on any game.
          </p>
        </div>
      ) : (
        <GamesDisplay 
          following={following}
          initialGames={queuedGames}
          showFilters={false}
          bypassInternalFilters={false}
          key={queue.join(',')}
        />
      )}
    </div>
  );
}

export default QueuePage; 