import React from 'react';
import { Eye, BookmarkPlus, Youtube, Star, HelpCircle } from 'lucide-react';
import { Link } from 'react-router-dom';

export default function AboutPage() {
  return (
    <div className="max-w-2xl mx-auto py-8 px-0 sm:px-4">
      <div className="bg-white rounded-xl shadow-sm p-6">
        {/* Header */}
        <div className="flex items-center gap-3 mb-6 pb-4 border-b">
          <div className="bg-blue-50 p-2 rounded-full">
            <HelpCircle className="w-6 h-6 text-blue-600" />
          </div>
          <h1 className="text-xl sm:text-2xl font-bold">How to Use Recap</h1>
        </div>
        
        <div className="space-y-6 sm:space-y-8">
          {/* Steps Container */}
          <div className="space-y-6 sm:space-y-8">
            {/* Follow Teams */}
            <section className="relative">
              <div className="bg-blue-50 p-4 rounded-lg">
                <div className="flex gap-3 items-start">
                  <Star className="w-5 h-5 text-blue-600 shrink-0 mt-1" />
                  <div>
                    <h2 className="text-lg font-semibold mb-2">Follow Your Teams</h2>
                    <p className="text-gray-600 text-sm sm:text-base">
                      Head to the "Follow" tab to select your favorite teams from various leagues. 
                      Once you follow teams, their games will appear in your feed, making it easy to 
                      keep track of matches you care about.
                    </p>
                  </div>
                </div>
              </div>
            </section>

            {/* New Filters Section */}
            <section className="relative">
              <div className="bg-orange-50 p-4 rounded-lg">
                <div className="flex gap-3 items-start">
                  <div className="shrink-0 mt-1">
                    <svg className="w-5 h-5 text-orange-600" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                      <polygon points="22 3 2 3 10 12.46 10 19 14 21 14 12.46 22 3"/>
                    </svg>
                  </div>
                  <div>
                    <h2 className="text-lg font-semibold mb-2">Filtering</h2>
                    <div className="space-y-4 text-sm sm:text-base text-gray-600">
                      <p>
                        Use our filtering system to quickly find the games you care about:
                      </p>
                      
                      <div className="border border-orange-200 rounded-lg p-4 space-y-4">
                        <div>
                          <h4 className="font-medium text-gray-800">Date Filters</h4>
                          <p className="text-gray-600 mt-1">
                            Choose between Today, Upcoming, and Recent games to narrow down your timeframe.
                          </p>
                        </div>

                        <div>
                          <h4 className="font-medium text-gray-800">League Selection</h4>
                          <p className="text-gray-600 mt-1">
                            Filter by specific leagues or use the "My Teams" filter to see games from teams you follow. Leagues are organized by sport for easy navigation.
                          </p>
                        </div>

                        <div>
                          <h4 className="font-medium text-gray-800">Active Filters</h4>
                          <p className="text-gray-600 mt-1">
                            Your active filters appear as tags at the top of the page. Click the X on any tag to remove that filter, or use the expand button to modify your selection.
                          </p>
                        </div>

                        <div>
                          <h4 className="font-medium text-gray-800">Quick Access</h4>
                          <p className="text-gray-600 mt-1">
                            The filter panel defaults to showing today's games for your followed teams, making it easy to find relevant content.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            {/* Spoiler Mode */}
            <section className="relative">
              <div className="bg-green-50 p-4 rounded-lg">
                <div className="flex gap-3 items-start">
                  <Eye className="w-5 h-5 text-green-600 shrink-0 mt-1" />
                  <div>
                    <h2 className="text-lg font-semibold mb-2">Use Spoiler Mode</h2>
                    <p className="text-gray-600 text-sm sm:text-base">
                      Toggle Spoiler Mode in the sidebar menu to control whether game scores are visible. 
                      This feature helps you avoid seeing results before you're ready, allowing you to 
                      browse games without ruining the result.
                    </p>
                  </div>
                </div>
              </div>
            </section>

            {/* Save Games */}
            <section className="relative">
              <div className="bg-purple-50 p-4 rounded-lg">
                <div className="flex gap-3 items-start">
                  <BookmarkPlus className="w-5 h-5 text-purple-600 shrink-0 mt-1" />
                  <div>
                    <h2 className="text-lg font-semibold mb-2">Save Games to Queue</h2>
                    <p className="text-gray-600 text-sm sm:text-base">
                      When you find a game you want to watch later, click the bookmark icon to add it to your queue. 
                      Your queue serves as a personal watchlist, making it simple to return to games when you have 
                      time to enjoy them.
                    </p>
                  </div>
                </div>
              </div>
            </section>

            {/* YouTube Search - Main Section */}
            <section id="youtube-settings" className="relative">
              <div className="bg-red-50 p-4 rounded-lg">
                <div className="flex gap-3 items-start">
                  <Youtube className="w-5 h-5 text-red-600 shrink-0 mt-1" />
                  <div>
                    <h2 className="text-lg font-semibold mb-2">YouTube Search</h2>
                    <div className="space-y-4 text-sm sm:text-base text-gray-600">
                      <div className="border border-red-200 rounded-lg p-4 mb-4">
                        <div className="space-y-4">
                          <div>
                            <h4 className="font-medium text-gray-800">Channel Trust</h4>
                            <p className="text-gray-600 mt-1">
                              Videos from channels higher in your <Link to="/channels" className="text-blue-600 hover:text-blue-700">list are prioritized</Link>. Official channels like NBA or ESPN rank highest by default.
                            </p>
                          </div>

                          <div>
                            <h4 className="font-medium text-gray-800">Content Quality</h4>
                            <p className="text-gray-600 mt-1">
                              Looks for videos that mention both teams and specifically contain "highlights" or "extended highlights" in the title.
                            </p>
                          </div>

                          <div>
                            <h4 className="font-medium text-gray-800">Freshness</h4>
                            <p className="text-gray-600 mt-1">
                              Prefers videos posted within 24 hours of the game, with same-day uploads ranking highest.
                            </p>
                          </div>

                          <div>
                            <h4 className="font-medium text-gray-800">Direct Launch</h4>
                            <p className="text-gray-600 mt-1">
                              Once a matching video is found, Recap opens YouTube directly to that highlight video, helping you avoid spoilers in related videos and comments.
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="text-gray-500 italic mt-2">
                        Pro tip: When opening highlights, you can quickly press 'F' to enter fullscreen and avoid seeing any spoilers in 
                        related videos or comments!
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            {/* Channel Priority - New Section */}
            <section className="relative">
              <div className="bg-yellow-50 p-4 rounded-lg">
                <div className="flex gap-3 items-start">
                  <Youtube className="w-5 h-5 text-yellow-600 shrink-0 mt-1" />
                  <div>
                    <h2 className="text-lg font-semibold mb-2">Channel Priority</h2>
                    <p>
                      The app is auto-set with the most popular channels for each league based on U.S. TV deals. For example, NBA games are checked in this order:
                    </p>
                    <ul className="list-disc ml-5 mt-2 space-y-1">
                      <li>First: Official channels (like NBA or House of Highlights)</li>
                      <li>Second: Major broadcasters (like ESPN)</li>
                      <li>Third: Other verified sports channels</li>
                    </ul>
                    <p className="mt-2">
                      You can customize this order in Channel Settings to prefer certain providers.
                    </p>
                    <p className="mt-2 text-amber-700">
                      Important: When customizing channels, make sure to spell channel names exactly as they appear on YouTube 
                      (e.g., "ESPN FC" not "ESPN Football"). The search will only work with exact matches.
                    </p>
                  </div>
                </div>
              </div>
            </section>

            {/* Smart Timing - New Section */}
            <section className="relative">
              <div className="bg-indigo-50 p-4 rounded-lg">
                <div className="flex gap-3 items-start">
                  <Youtube className="w-5 h-5 text-indigo-600 shrink-0 mt-1" />
                  <div>
                    <h2 className="text-lg font-semibold mb-2">Smart Timing</h2>
                    <p>
                      The app waits a set amount of time after each game (usually 3-4 hours) before showing highlight option:
                    </p>
                    <ul className="list-disc ml-5 mt-2 space-y-1">
                      <li>NBA/NHL/MLB: Waits 3.5 hours after game start</li>
                      <li>NFL: Waits 4.5 hours after kickoff</li>
                      <li>Soccer: Waits 3 hours after kickoff</li>
                      <li>This ensures full highlights are available</li>
                    </ul>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
} 