import { Outlet, useLocation, Routes, Route } from 'react-router-dom';
import Navigation from './components/Navigation';
import { useState, useEffect } from 'react';
import { initializeLeagueLogos } from './config/leagues.js';
import LoadingSpinner from './components/LoadingSpinner';
import GuestBanner from './components/GuestBanner';
import { useApp } from './contexts/AppContext';
import GuestGuide from './components/GuestGuide';
import GamesDisplay from './components/GamesDisplay';
import { ForgotPassword } from './components/ForgotPassword';

export default function App() {
  const [leaguesInitialized, setLeaguesInitialized] = useState(false);
  const [showGuestGuide, setShowGuestGuide] = useState(false);
  const [guestGuideComplete, setGuestGuideComplete] = useState(false);
  const { 
    isGuest, 
    currentUser, 
    isNewGuest, 
    setIsNewGuest,
    isFirstTimeUser,
    markGuideComplete 
  } = useApp();
  const location = useLocation();

  useEffect(() => {
    const initLeagues = async () => {
      try {
        await initializeLeagueLogos();
        setLeaguesInitialized(true);
      } catch (error) {
        console.error('Error initializing leagues:', error);
        setLeaguesInitialized(true);
      }
    };

    initLeagues();
  }, []);

  useEffect(() => {
    if ((isGuest && isNewGuest) || isFirstTimeUser) {
      setShowGuestGuide(true);
    }
  }, [isGuest, isNewGuest, isFirstTimeUser]);

  const handleGuestGuideComplete = async () => {
    setShowGuestGuide(false);
    if (isGuest) {
      setIsNewGuest(false);
    } else {
      await markGuideComplete();
    }
    setGuestGuideComplete(true);
  };

  if (!leaguesInitialized) {
    return (
      <div className="min-h-screen bg-gray-50 flex items-center justify-center">
        <LoadingSpinner />
      </div>
    );
  }

  const isAuthPath = location.pathname === '/login' || location.pathname === '/register';
  if (isAuthPath && !currentUser && !isGuest) {
    return <Outlet />;
  }

  return (
    <div className="min-h-screen bg-gray-50">
      <Navigation />
      <main className="container mx-auto px-3 sm:px-4 py-2">
        <Outlet context={{ 
          isGuestGuideComplete: guestGuideComplete 
        }} />
      </main>
      {showGuestGuide && <GuestGuide onComplete={handleGuestGuideComplete} />}
    </div>
  );
}

export const routes = (
  <Routes>
    <Route path="/forgot-password" element={<ForgotPassword />} />
  </Routes>
);