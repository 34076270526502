import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { auth } from '../services/firebase';
import { signInWithEmailAndPassword } from 'firebase/auth';
import LoadingSpinner from './LoadingSpinner';
import { useApp } from '../contexts/AppContext';

export function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { setGuestMode, setIsNewGuest } = useApp();

  const getReadableErrorMessage = (error) => {
    switch (error.code) {
      case 'auth/invalid-credential':
        return 'Invalid email or password. Please try again.';
      case 'auth/user-not-found':
        return 'No account found with this email address.';
      case 'auth/wrong-password':
        return 'Incorrect password. Please try again.';
      case 'auth/too-many-requests':
        return 'Too many failed login attempts. Please try again later.';
      case 'auth/user-disabled':
        return 'This account has been disabled. Please contact support.';
      default:
        return 'An error occurred during sign in. Please try again.';
    }
  };

  const handleGuestAccess = () => {
    console.log('Enabling guest mode...');
    setGuestMode(true);
    setIsNewGuest(true);
    console.log('Guest mode enabled, navigating...');
    navigate('/', { replace: true });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
      setError('');
      setLoading(true);
      await signInWithEmailAndPassword(auth, email, password);
      // Navigation will be handled by AppContext
    } catch (error) {
      console.error('Login error:', error);
      setError(getReadableErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col justify-start pt-16 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <h2 className="text-2xl font-semibold mb-1 text-center">Welcome to Recap 👋</h2>
        <p className="text-gray-400 text-xs text-center mb-4">
          a app for sports highlights
        </p>
      </div>

      <div className="mt-4 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow-sm rounded-xl sm:px-10">
          <form onSubmit={handleSubmit} className="space-y-6">
            {error && (
              <div className="rounded-md p-4 bg-red-50">
                <div className="text-sm text-red-700">{error}</div>
              </div>
            )}

            <div>
              <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                Email
              </label>
              <input
                id="email"
                type="email"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="mt-1 w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 outline-none"
              />
            </div>

            <div>
              <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                Password
              </label>
              <input
                id="password"
                type="password"
                required
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="mt-1 w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 outline-none"
              />
            </div>

            <button
              type="submit"
              disabled={loading}
              className="w-full py-2 px-4 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors disabled:opacity-50 flex items-center justify-center gap-2"
            >
              {loading && <LoadingSpinner />}
              {loading ? 'Signing in...' : 'Sign in'}
            </button>

            <div className="flex justify-between text-sm">
              <Link to="/register" className="text-gray-600 hover:text-gray-800">
                Don't have an account? Sign up
              </Link>
              <Link 
                to="/forgot-password" 
                className="text-gray-600 hover:text-gray-800"
              >
                Forgot password?
              </Link>
            </div>
          </form>

          <div className="mt-6 pt-6 border-t">
            <button
              onClick={handleGuestAccess}
              className="w-4/5 mx-auto py-1.5 px-4 rounded-lg text-gray-700 bg-blue-50 hover:bg-blue-100 transition-colors flex items-center justify-center gap-2"
            >
              <span>👀</span> Just looking around
            </button>
          </div>
        </div>
      </div>
    </div>
  );
} 