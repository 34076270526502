import React, { useState, useEffect } from 'react';

const SPORTS_BALLS = ['⚽', '🏀', '🏈', '⚾', '🎾', '🏐', '🏉'];

// Function to shuffle the array
const shuffleArray = (array) => {
  return array.sort(() => Math.random() - 0.5);
};

// Custom keyframes animations for rolling
const rollStyles = `
  @keyframes roll {
    0%, 100% { 
      transform: translateX(-20px) rotate(0deg);
      animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    }
    50% { 
      transform: translateX(20px) rotate(360deg);
      animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    }
  }

  @keyframes rollOval {
    0%, 100% { 
      transform: translateX(-20px) rotate(0deg) scale(1, 1);
      animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    }
    25% {
      transform: translateX(0px) rotate(180deg) scale(0.9, 1.1);
    }
    50% { 
      transform: translateX(20px) rotate(360deg) scale(1, 1);
      animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    }
    75% {
      transform: translateX(0px) rotate(540deg) scale(0.9, 1.1);
    }
  }

  .roll {
    animation: roll 2s infinite;
    transform-origin: center center;
  }

  .roll-oval {
    animation: rollOval 2s infinite;
    transform-origin: center center;
  }

  .ball-container {
    position: relative;
    display: inline-block;
    padding: 0 20px;
  }
`;

function LoadingSpinner() {
  const [currentBallIndex, setCurrentBallIndex] = useState(0);
  const [shuffledBalls, setShuffledBalls] = useState([]);

  useEffect(() => {
    setShuffledBalls(shuffleArray([...SPORTS_BALLS]));

    const interval = setInterval(() => {
      setCurrentBallIndex((prev) => (prev + 1) % SPORTS_BALLS.length);
    }, 2000);

    const styleSheet = document.createElement("style");
    styleSheet.innerText = rollStyles;
    document.head.appendChild(styleSheet);

    return () => {
      clearInterval(interval);
      document.head.removeChild(styleSheet);
    };
  }, []);

  // Check if current ball is football or rugby
  const isOvalBall = shuffledBalls[currentBallIndex] === '🏈' || shuffledBalls[currentBallIndex] === '🏉';

  return (
    <div className="loading-spinner">
      <div className="ball-container">
        <span className={`inline-block text-3xl ${isOvalBall ? 'roll-oval' : 'roll'}`}>
          {shuffledBalls[currentBallIndex]}
        </span>
      </div>
    </div>
  );
}

export default LoadingSpinner; 